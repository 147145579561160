.trendChart {
  display: flex;
  justify-content: space-between;
  width: 100%;
  height: 100%;
  padding-top: 15px;
  padding-bottom: 15px;
  background-color: var(--container-background-color);
  border: var(--container-border);
  border-radius: var(--container-border-radius);
  backdrop-filter: var(--container-backdrop-filter);
}

.trendCanvasChart {
  display: flex;
  flex-direction: column;
  height: 90%;
  width: 95%;
  gap: var(--gap);
  margin-top: auto;
  margin-left: auto;
  margin-right: auto;
  justify-content: space-between;
  align-items: center;
}
.trendCanvasChartData {
  display: flex;
  align-items: center;
  width: 100%;
  height: 100%;
  object-fit: contain;
  overflow: hidden;
}

.trendCanvasChartLabel {
  font-size: 16px;
}
.trendCanvasChartLine {
  color: white;
}
.filters {
  display: flex;
  flex-direction: row;
  justify-content: center;
  gap: 20px;
  margin: auto;
  width: 60%;
}
.filterInputGroup {
  display: flex;
  gap: 10px;
}
.filterInputGroup label {
  align-self: center;
  font-size: 14px;
  font-weight: 600;
}
.filterInputGroup input {
  display: flex;
  width: 150px;
  outline: none;
  border: none;
  border-radius: var(--button-border-radius);
  padding: 5px;
  font-size: 14px;
  justify-content: center;
  align-items: center;
  margin: auto;
  background-color: var(--white-color);
}
.hr {
  border: 1px solid rgb(139, 139, 139);
  height: 50px;
  margin: auto;
  width: 0.01px;
}
