.customConfirmDeleteDialog {
  height: fit-content;
  padding: 20px;
  width: fit-content;
  background-color: white;
  border-radius: 20px;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
}
.customConfirmDeleteDialogContent {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 0px;
}
.customConfirmDeleteDialogContent p {
  font-size: 20px;
  font-weight: 600;
  margin-top: 0px;
}
.customConfirmDeleteDialogContentNote {
  width: 80%;
  color: var(--disabled-color);
  text-align: center;
  margin-bottom: 20px;
}
.customConfirmDeleteDialogBtns {
  display: flex;
  width: 100%;
  gap: 10px;
  justify-content: center;
  margin: auto;
}
.customConfirmDeletebtnConfirm {
  padding: 10px;
  background-color: var(--heading-details-color);
  border-radius: var(--button-border-radius);
  color: #fff;
  cursor: pointer;
}
.customConfirmDeletebtnCancel {
  padding: 10px;
  background-color: var(--white-color);
  border-radius: var(--button-border-radius);
  color: var(--font-color);
  border: 1px solid var(--font-color);
  cursor: pointer;
}
