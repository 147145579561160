.custom-node .react-flow__handle {
  opacity: 0;
}

.custom-node .react-flow__handle.source {
  right: -10px;
}

.custom-node .react-flow__handle.target {
  left: -10px;
}

.custom-node .react-flow__node:focus {
  outline: none;
}
