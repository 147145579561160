.login {
  display: flex;
  width: 100%;
  height: 100%;
  background-color: var(--input-background-color);
  margin: auto;
  position: relative;
}
.loginBackLeft {
  width: 50%;
  height: 100vh;
  border-radius: 0 0 100px 0;
}
.loginForm {
  position: relative;
  width: 50%;
  display: flex;
  margin-left: -25%;
  margin-top: auto;
  margin-bottom: auto;
  background-color: var(--container-background-color);
  backdrop-filter: var(--container-backdrop-filter);
  border-radius: var(--container-border-radius);
  border: var(--container-border);
  padding: 10px;
  justify-content: center;
  align-items: center;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
}
.loginImage {
  width: 60%;
  object-fit: fill;
}
.logoLogin {
  background-color: var(--primary-color);
  color: white;
}
.logoEnd {
  height: 25%;
  width: 25%;
}
.loginImage img {
  width: 100%;
  height: 100%;
}
.loginContent {
  width: 40%;
}
.loginSignUpSwitcher {
  display: flex;
  position: absolute;
  top: 10px;
  right: 10px;
}
.signInBtn {
  font-size: 12px;
  padding: 3px;
  padding-left: 10px;
  padding-right: 10px;
  font-weight: 700;
  cursor: pointer;
  border-radius: 10px 0 0 10px;
  background-color: var(--light-heading-color);
  color: var(--light-font-color);
}
.signUpBtn {
  font-size: 12px;
  font-weight: 700;
  cursor: pointer;
  padding: 3px;
  padding-left: 10px;
  padding-right: 10px;
  border-radius: 0 10px 10px 0;
  background-color: var(--light-heading-color);
  color: var(--light-font-color);
}
.signInBtn.selected {
  background-color: var(--primary-color);
  color: black;
  font-weight: 700;
}
.signUpBtn.selected {
  background-color: var(--primary-color);
  color: black;
  font-weight: 700;
}
.loginFormContent {
  width: 90%;
  display: flex;
  flex-direction: column;
}

@media screen and (max-width: 600px) {
  .loginForm {
    width: 90%;
    margin-left: -48%;
  }
}
.loginBtnContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 20px;
  width: 100%;
  margin: auto;
  margin-top: 20px;
}
.loginBtn {
  display: flex;
  width: 50%;
  border-radius: var(--button-border-radius);
  background-color: var(--background-color-violet);
  padding: 5px;
  color: var(--light-font-color);
  border: none;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.loginImg {
  width: 100%;
}
.Loginlogo {
  display: flex;
  width: 30%;
  margin-top: 10px;
  margin-left: 10px;
}
.landingLogo {
  width: 70%;
  margin: auto;
  padding: 30px;
}
.org {
  display: flex;
  flex-direction: column;
  width: 100vw;
  height: 100vh;
  justify-content: center;
  align-items: center;
  margin: auto;
}
.createOrgContent {
  width: 30%;
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.orgHeading {
  display: flex;
  text-align: center;
  align-items: center;
  gap: 10px;
  justify-content: flex-start;
  font-size: var(--edge-statistics-heading-font-size);
  font-weight: var(--heading-font-weight);
}
.orgBackBtn {
  display: flex;
  cursor: pointer;
  font-size: var(--edge-statistics-heading-font-size);
}
.orgBackBtn span {
  text-align: center;
}
.orgSubHeading {
  text-align: left;
  font-weight: 400;
  margin-left: 30px;
}
.inviteSubHeading {
  text-align: left;
  font-weight: 400;
}
.orgForm {
  position: relative;
  width: 100%;
  display: flex;
  margin: auto;
  background-color: var(--container-background-color);
  backdrop-filter: var(--container-backdrop-filter);
  border-radius: var(--container-border-radius);
  border: var(--container-border);
  padding: 10px;
  justify-content: center;
  align-items: center;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
}
.orgFormContent {
  width: 100%;
  padding: 5px;
}
.orgBtns {
  width: 100%;
  display: flex;
  margin: auto;
  margin-top: 20px;
  align-items: center;
  justify-content: center;
}
.orgBtnStyle {
  color: var(--white-color);
  background-color: #375dfb;
  outline: none;
  font-weight: var(--heading-font-weight);
  border-radius: 10px;
  width: 90%;
  cursor: pointer;
  padding: 10px;
}
.orgBtnStyle:hover {
  background-color: #2651fd;
}
.orgJoinContent {
  display: flex;
  margin-top: 10px;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  text-align: center;
}
.register {
  display: flex;
  width: 100vw;
  height: 100vh;
}
.registerForm {
  position: relative;
  width: 90%;
  height: 90%;
  display: flex;
  flex-direction: column;
  margin-top: auto;
  margin-bottom: auto;
  margin-left: auto;
  margin-right: auto;
  background-color: var(--container-background-color);
  backdrop-filter: var(--container-backdrop-filter);
  border-radius: var(--container-border-radius);
  border: var(--container-border);
  padding: 10px;
  /* justify-content: center;
  align-items: center; */
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
}
.registerLoginlogo {
  display: flex;
  justify-content: flex-end;
  width: 100%;
  height: 5%;
  object-fit: contain;
}
.registerLoginlogo img {
  width: fit-content;
  height: 100%;
}
.registerContent {
  width: 100%;
  height: 90%;
  justify-content: space-between;
  display: flex;
}

.registerFormContent {
  width: 40%;
  height: 100%;
  justify-content: center;
  align-items: center;
  display: flex;
  flex-direction: column;
}
.registerLandingImage {
  width: 60%;
  height: 100%;
  object-fit: contain;
}
.registerLandingImage img {
  width: 100%;
  height: 100%;
}
.signUpText {
  display: flex;
  width: fit-content;
  height: 10%;
  justify-content: center;
  align-items: center;
  margin: auto;
  text-align: center;
  font-size: 23px;
  font-weight: var(--heading-font-weight);
}
.registerContentForm {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.loginPopupContent {
  display: flex;
  flex-direction: column;
  gap: 10px;
  height: 100%;
  width: fit-content;
  justify-content: center;
  align-items: center;
  margin: auto;
}
.orgForm {
  display: flex;
  gap: 30px;
  flex-direction: column;
}
.loginCloseIcon {
  position: absolute;
  right: -10px;
  color: var(--light-red-color);
  top: -5px;
  cursor: pointer;
}

.dontHaveAnAccount {
  display: flex;
  width: 100%;
  justify-content: center;
}
.signUpLink {
  color: var(--primary-color);
  font-weight: var(--heading-font-weight);
  margin-left: 5px;
}
.skipInvite {
  display: flex;
  width: 100%;
  margin-top: 10px;
  text-align: center;
  justify-content: center;
}
