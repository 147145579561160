.customNodeContainer {
  display: flex;
  margin: auto;
  height: max-content;
  flex-direction: column;
  border-radius: 10px;
  resize: both;
}
.customConstantNode {
  display: flex;
  gap: 10px;
  justify-content: center;
  flex-direction: row;
}
.customConstantNodeBtn {
  cursor: pointer;
  margin: auto;
}

.customOperatorContainer {
  display: flex;
  margin: auto;
  height: 70px;
  width: 120px;
  border-radius: 10px !important;
  border: none !important;
  flex-direction: column;
}
.customOperatorContainer.four {
  height: 110px;
  width: 120px;
}
.customOperatorContainer.ten {
  height: 230px;
  width: 120px;
}
.customOperatorContainer .customNodeHeading {
  justify-content: center;
  height: 30px;
}
.customOperatorContainer.large {
  display: flex;
  margin: auto;
  height: 70px;
  width: 150px;
  border-radius: 10px !important;
  border: none !important;
  flex-direction: column;
}
.customNodeHeading {
  display: flex;
  height: 20px;
  width: 100%;
  align-items: center;
  justify-content: flex-start;
  font-weight: var(--heading-font-weight);
  margin: auto;
  font-size: var(--font-size);
}
.customInputNodeContent {
  display: flex;
  flex-direction: column;
  width: fit-content;
  text-align: left;
  padding: 10px;
  gap: 2px;
  font-size: 10px;
  height: 90%;
  border-radius: 10px;
}
.customNodeContent {
  padding: 4px;
  height: 90%;
  margin: auto;
  border-radius: 10px;
}

.handleLabel {
  position: absolute;
  top: 28px;
  left: 10px;
  font-size: 10px;
  color: var(--font-color);
}
.handleWithLabel {
  position: absolute;
  top: 43px;
  left: 10px;
  font-size: 10px;
  color: var(--font-color);
}

.switchLabel {
  position: relative;
  display: inline-block;
  width: 40px;
  height: 20px;
}

.switchLabel input {
  opacity: 0;
  width: 0;
  height: 0;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: 0.4s;
  transition: 0.4s;
  border-radius: 34px;
}

.slider:before {
  position: absolute;
  content: "";
  height: 16px;
  width: 16px;
  left: 3px;
  bottom: 2px;
  background-color: white;
  -webkit-transition: 0.4s;
  transition: 0.4s;
  border-radius: 50%;
}
.switchLabel.active .slider {
  background-color: #ff963f;
}

.switchLabel.active .slider:before {
  -webkit-transform: translateX(20px);
  -ms-transform: translateX(20px);
  transform: translateX(20px);
}
.customHandle {
  background-color: var(--font-color) !important;
  border: 2px solid var(--font-color) !important;
  width: 5px !important;
  height: 5px !important;
  border-radius: 50% !important;
}
.switchLabel span {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  text-align: center;
  font-size: 14px;
  font-weight: bold;
  color: #333;
  line-height: 34px;
  z-index: 1;
  user-select: none;
}

.switchLabel.active span {
  color: #fff;
}
