.addPointsForm {
  position: absolute;
  min-width: 25%;
  height: 95%;
  right: 0;
  margin-top: 10px;
  padding: 10px;
  display: flex;
  z-index: 100;
  flex-direction: column;
  background-color: var(--dialog-bg);
}
.addPointsFormHeading {
  font-size: 24px;
  text-align: center;
}
.addPointsFormContents {
  display: flex;
  gap: 10px;
  flex-direction: column;
  height: 100%;
}
.addPointsFormDiscoveringImg {
  width: 250px;
  margin: auto;
  object-fit: cover;
}
.addPointsFormDiscoveringImg img {
  width: 100%;
  margin: auto;
}
.addDeviceFormContentsDeviceList {
  display: flex;
  flex-direction: column;
  height: 100%;
}
.addDeviceContentsDeviceListSearch {
  display: flex;
  width: 70%;
  margin-left: auto;
  margin-right: auto;
  margin-top: 20px;
}

.addPointsFormContentsDeviceListContents {
  display: flex;
  height: 40vh;
  margin-left: 30px;
  width: 80%;
  overflow: scroll;
  flex-direction: column;
  align-items: self-start;
}
.addPointsFormContentsDeviceListItem {
  display: flex;
  gap: 10px;
}
.addPointsFormContentsDeviceListItem.selectAll {
  /* margin-top: -20px; */
  margin-bottom: 20px;
  justify-content: center;
  font-weight: 600;
  text-align: center;
}
.addPointsFormContentsBtns {
  margin: auto;
  display: flex;
  gap: 10px;
  margin-top: 30px;
}
.addPointsFormContentsSaveBtn {
  background-color: var(--light-green-color);
  color: white;
  width: 80px;
  text-align: center;
  padding-left: 10px;
  padding-right: 10px;
  cursor: pointer;
  padding-top: 5px;
  padding-bottom: 5px;
  border-radius: 10px;
}
.addPointsFormContentsCancelBtn {
  background-color: var(--light-red-color);
  color: white;
  width: 80px;
  text-align: center;
  padding-left: 10px;
  padding-right: 10px;
  padding-top: 5px;
  padding-bottom: 5px;
  cursor: pointer;
  border-radius: 10px;
}
