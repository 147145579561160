.logs {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  height: 100%;
  overflow: scroll;
}

.logsContent {
  display: flex;
  flex-direction: column;
  height: 100%;
  margin-top: 10px;
}
.logsFilterContainer {
  display: flex;
  flex-direction: column;
  height: fit-content;
  width: 90%;
  height: 7%;
  margin: auto;
}
.logsFilterPriorityChips {
  display: flex;
  gap: 10px;
  margin: auto;
  align-items: center;
  justify-content: center;
}
.logsFilterPriorityChip {
  display: flex;
  width: 70px;
  justify-content: center;
  padding: 5px;
  cursor: pointer;
  color: white;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
  border-radius: var(--button-border-radius);
}
.warning {
  background-color: #ea500d;
}
.error {
  background-color: var(--light-red-color);
}
.debug {
  background-color: var(--primary-color);
}
.info {
  background-color: var(--light-green-color);
}
.clear {
  background-color: var(--container-background-color);
  border: var(--container-border);
  color: var(--light-font-color);
}
.logsSearchBox {
  background-color: var(--item-selected-color);
  width: 30%;
  display: flex;
  justify-content: space-between;
  border-radius: var(--button-border-radius);
  align-items: center;
  font-weight: 600;
  padding: 10px;
  border: 1px solid black;
}
.logsSearchBox input {
  outline: none;
  border: none;
  background-color: transparent;
}
.logsFilterOptions {
  display: flex;
  width: 100%;
  justify-content: space-between;
}
.logsDateTimeFilterBtn {
  background-color: var(--heading-details-color);
  color: var(--light-font-color);
  display: flex;
  gap: 5px;
  font-weight: 800;
  padding-left: 10px;
  padding-right: 10px;
  padding-top: 6px;
  padding-bottom: 6px;
  align-items: center;
  cursor: pointer;
  border-radius: var(--button-border-radius);
}
.logsContentText {
  height: 80%;
  width: 95%;
  margin-left: auto;
  margin-right: auto;
  margin: auto;
  justify-content: center;
  align-items: center;
  text-align: justify;
  overflow: scroll;
  background-color: black;
  border-radius: var(--container-border-radius);
  color: white;
}
.deviceLogStatisticsNoContentLog {
  display: flex;
  align-self: center;
  justify-content: center;
}
