.organizationsSettingsContent {
  display: flex;
  flex-grow: 1;
}
.organizationContent {
  display: flex;
  gap: 15px;
  width: 100%;
  height: 100%;
  margin: auto;
}
.organizationBrowser {
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
}
.organizationBrowserHeading {
  display: flex;
  width: 95%;
  margin-left: auto;
  margin-right: auto;
  height: 8%;
  align-items: center;
  justify-content: space-between;
  font-size: var(--custom-header-font-size);
  font-weight: var(--heading-font-weight);
}
.organizationBrowserContent {
  display: flex;
  width: 95%;
  margin-left: auto;
  margin-right: auto;
  justify-content: flex-start;
  align-items: flex-start;
  flex-direction: column;
  gap: 5px;
}
.organizationItem {
  display: flex;
  gap: 5px;
  flex-direction: column;
  width: 95%;
  margin-left: auto;
  margin-right: auto;
  align-items: flex-start;
}
.organizationName {
  display: flex;
  gap: 5px;
  font-size: var(-heading-font-size);
  font-weight: var(--heading-font-weight);
}
.sitesList {
  display: flex;
  width: 70%;
  font-size: 12;
  font-weight: var(--base-font-weight);
  margin-left: auto;
  margin-right: auto;
  align-items: flex-start;
  flex-direction: column;
  gap: 5px;
}
/* OrganizationCanvas Styles */

.organizationCanvas {
  display: flex;
  flex-direction: column;
  gap: 10px;
  flex-grow: 1;
}
.organizationCanvasHeading {
  width: 100%;
  font-size: var(--custom-header-font-size);
  font-weight: var(--heading-font-weight);
}
.organizationCanvascontent {
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
}
.organizationCanvasHeadingText {
  margin-top: 15px;
  margin-left: 15px;
  font-size: var(--custom-header-font-size);
  font-weight: var(--heading-font-weight);
}
.orgContent {
  height: 70vh;
  width: 98%;
  margin: auto;
  display: flex;
  flex-wrap: wrap;
  overflow: scroll;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 10px;
  align-content: flex-start;
  flex-direction: row;
}
.orgCard {
  display: flex;
  flex-direction: column;
  width: 300px;
  height: 170px;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
}
.orgCardBtnGroup {
  display: flex;
  gap: 5px;
  cursor: pointer;
}
.orgCardHeading {
  width: 95%;
  margin-left: auto;
  margin-right: auto;
  height: 30%;
  align-items: center;
  display: flex;
  justify-content: space-between;
  font-size: var(--custom-header-font-size);
  font-weight: var(--heading-font-weight);
}

.orgCardContent {
  display: flex;
  flex-direction: column;
  width: 95%;
  font-size: var(--heading-font-size);
  margin-left: auto;
  gap: 5px;
  margin-right: auto;
}
.orgCardContentBlock {
  display: flex;
  align-items: center;
  gap: 5px;
  background-color: var(--container-background-color);
  border: var(--container-border);
  border-radius: 5px;
  padding: 5px;
}
.orgCardIcon {
  display: flex;
  padding: 3px;
}
.orgCardDeleteIcon {
  display: flex;
  padding: 3px;
  border-radius: 5px !important;
}
.emptyOrgContent {
  display: flex;
  flex-direction: column;
  gap: 5px;
  height: 100%;
  width: 100%;
  font-size: 20px;
  font-weight: 300;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}
.orgCardContent.center {
  margin: auto;
  height: 100%;
  width: 100%;
}

/* New ORG Form Styles */
.overlayContent {
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
}
.newOrgForm {
  display: flex;
  flex-direction: column;
  width: 30%;
  height: 60%;
  margin: auto;
  justify-content: flex-start;
  background-color: var(--dialog-color);
  border-radius: var(--container-border-radius);
}
.newOrgFormHeading {
  display: flex;
  width: 100%;
  height: 10%;
  align-items: center;
  justify-content: center;
  font-size: var(--custom-header-font-size);
  font-weight: var(--heading-font-weight);
}
.newOrgFormContents {
  display: flex;
  width: 100%;
  justify-content: flex-start;
  align-items: center;
  margin-bottom: auto;
  height: 75%;
  overflow: scroll;
  flex-direction: column;
}
.newOrgFormBtns {
  display: flex;
  width: 100%;
  gap: 10px;
  justify-content: center;
  margin: auto;
}
.newOrgFormContents .customFormInput {
  margin-top: 0px;
}
.addSiteBtn {
  display: flex;
  gap: 5px;
  width: fit-content;
  align-items: center;
  justify-content: center;
  border-radius: 5px;
  padding: 8px;
}
.orgSiteInput {
  width: 97%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.orgFormDeleteIcon {
  margin-top: 10px;
  cursor: pointer;
  /* padding-right: 10px; */
}

/* ORG SETTINGS STYLE */
.latLongInputGroup {
  display: flex;
  width: 90%;
  margin-right: auto;
  margin-bottom: 10px;
  margin-left: auto;
  align-items: flex-end;
  justify-content: space-between;
  gap: 5px;
}
.inputGroupPart {
  display: flex;
  width: 50%;
  flex-direction: column;
  gap: 10px;
}
.inputGroupPart input {
  display: flex;
  background-color: var(--container-background-color);
  border: var(--container-border);
  border-radius: var(--input-border-radius);
  color: var(--font-color);
  width: 90%;
  outline-width: 0.1px;
  outline-style: none;
  color: var(--font-color);

  padding-top: 10px;
  padding-bottom: 10px;
  padding-left: 10px;
}
.inputGroupPart input:focus {
  border: 1px solid var(--primary-color);
}
.inputGroupPart.location {
  height: 60%;
  width: fit-content;
  cursor: pointer;
  align-items: center;
  justify-content: center;
}
.getLocationIcon {
  display: flex;
  align-items: center;
  width: fit-content;
}
.siteCard {
  height: 220px;
}
