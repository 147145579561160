.deviceBrowser {
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.deviceBrowserHeader {
  display: flex;
  width: 90%;
  gap: 16px;
  font-size: 16px;
  height: 8%;
  font-weight: var(--heading-font-weight);
  justify-content: space-between;
  margin-left: auto;
  margin-right: auto;
  text-align: center;
  align-items: center;
}
.addNetworksBtn {
  margin-top: auto;
  margin-bottom: auto;
  cursor: pointer;
  outline: none;
  color: var(--background-color-violet);
}
.deviceBrowserHeaderBtns {
  display: flex;
  gap: 5px;
  justify-content: center;
}
.deviceBrowserContent {
  display: flex;
  width: 98%;
  height: 84%;
  margin-left: auto;
  margin-right: auto;
  /* gap: 16px; */
  overflow-y: scroll;
  overflow-x: scroll;
  /* overflow: scroll !important; */
}
.deviceBrowserContent::-webkit-scrollbar {
  width: 0px;
}
.deviceBrowserContent::-webkit-scrollbar:horizontal {
  display: flex !important;
  height: 7px;
}

.deviceBrowserContent::-webkit-scrollbar-track {
  background: var(--container-background-color);
  border-radius: var(--container-border-radius);
  border: var(--container-border);
}
.deviceBrowserContent::-webkit-scrollbar-thumb {
  border-radius: 20px;
  background: #555;
}
.deviceBrowserContent::-webkit-scrollbar-thumb:hover {
  background: var(--font-color);
}
.objectBrowserList {
  width: 90% !important;
  margin-left: auto;
  margin-right: auto;
}
.deviceBrowserNetworks {
  width: 100%;
  display: flex;
  flex-direction: column;
  margin: 0px;
  padding: 0px;
  height: 75vh;
}
.deviceBrowserNetworkhead {
  display: flex;
  flex-direction: row;
  gap: 10px;
  padding: 5px;
  margin-top: auto;
  font-size: 15px;
  line-height: 20px;
  margin-bottom: auto;
  margin: 0px;
  font-weight: bold;
}
.deviceBrowserhead {
  display: flex;
  flex-direction: row;
  gap: 10px;
  padding: 5px;
  margin-top: auto;
  font-weight: bold;
  line-height: 20px;
  margin-bottom: auto;
  margin: 0px;
}
.deviceBrowserDevices {
  margin-left: -20px;
}

.deviceBrowserDeviceName {
  display: flex;
  font-weight: 600;
  padding: 5px;
}
.deviceBrowserPointList {
  display: flex;
  padding: 3px;
}
.deviceBrowserPointList span {
  padding: 5px;
}
.deviceBrowserPoints {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}
.deviceBrowserSearch {
  display: flex;
  height: 6%;
}
