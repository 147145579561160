.deviceNetworkProperties {
  display: flex;
  flex-direction: column;
  text-align: center;
  gap: 20px;
  flex-grow: 1;
}

.networkListingStyle {
  height: 33px;
}
.deviceNetworkPropertiesContainerBox {
  background-color: blue;
}

.deviceNetworkPropertiesHeaderContainerBox {
  display: flex;
  align-items: center;
  padding: 10px;
  white-space: nowrap;
  padding-left: 20px;
  padding-top: 20px;
}

.deviceNetworkPropertiesContents {
  background-color: var(--background-color);
  margin-top: 10px;
  height: 100%;
  border-radius: var(--container-border-radius);
  padding-left: 100px;
  padding-right: 100px;
}

.headerGroup {
  display: flex;
  height: fit-content;
  cursor: pointer;
  color: var(--white-color);
  text-align: left;
  gap: 5px;
}

.deviceNetworkPropertiesHeadingBox {
  font-size: 20px;
  font-weight: bold;
  margin-left: 40px;
}

.deviceNetworkPropertiesHeadingBox {
  display: flex;
  align-items: center;
}
.deviceNetworkPropertiesHeader {
  display: flex;
  width: -moz-fit-content;
  border-radius: var(--heading-border-radius);
  height: -moz-fit-content;
  padding-bottom: 4px;
  background-color: var(--heading-details-color);
  padding-left: 8px;
  padding-right: 8px;
  font-size: var(--heading-size);
  color: var(--light-font-color);
  margin-bottom: 19px;
  margin-left: 20px;
  cursor: pointer;
}
.deviceNetworkPropertiesHeader span {
  margin: auto;
}
.deviceNetworkPropertiesContent {
  display: flex;
  width: 70%;
  margin-top: 20px;
  margin-left: 14%;
  gap: 20px;
}
.deviceNetworkPropertiesPartContent {
  width: 50%;
  display: flex;
  flex-direction: column;
}

.deviceNetworkPropertiesGroup {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.deviceNetworkPropertyLabel {
  flex: 1.5;
  text-align: left;
  font-size: 16px;
  font-weight: 700;
}

.deviceNetworkPropertyData {
  flex: 2;
  text-align: left;
  font-size: 16px;
}
.deviceNetworkPropertyData input {
  outline: none;
  border: none;
  padding: 10px;
  font-size: 16px;
  color: var(--font-color);
  border-radius: 10px;
  background-color: var(--light-button-color);
}
.deviceNetworkPropertyData.inactive {
  color: red;
}
.deviceNetworkPropertiesBtnsGroup {
  display: flex;
  width: 90%;
  margin-top: 40px;
  justify-content: center;
  gap: 20px;
  margin-left: auto;
  margin-right: auto;
}

.deviceNetworkPropertiesHeadingNetwork {
  font-weight: 400;
  font-size: 15px;
  margin-left: 13px;
  margin-bottom: 5px;
  margin-top: 3px;
}
