.deviceDetailsPropertiesContent {
  display: flex;
  flex-direction: column;
  bottom: 30px;
}

.deviceDetailsPropertiesContentInputs {
  display: flex;
  width: 70%;
  margin-left: 14%;
  gap: 20px;
  margin-top: 23px;
}
.networkListingStyle {
  display: flex;
  gap: 10px;
  padding-bottom: 10px;
  font-size: var(--font-size-device-heading);
  font-weight: bold;
}

.deviceDetailsPropertiesContentPartInputs {
  width: 50%;
}
.checkboxContainer {
  display: flex;
  align-items: center;
}

.checkboxContainer label {
  margin-right: 8px; /* Add some space between label and checkbox */
}
.deviceDetailsPropertiesSavebtn {
  display: flex;
  margin: auto;
  color: black;
  padding-left: 15px;
  padding-right: 15px;
  border-radius: 10px;
  margin-top: 40px;
  font-size: 14px;
  margin-bottom: -20px;
  margin-right: auto;
  margin-left: 40%;
  cursor: pointer;
  justify-content: center;
  align-items: center;
  font-weight: 600;
  background-color: var(--light-button-color);
}
.deviceDetailsPropertiesGroup {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-right: auto;
  margin-left: auto;
  white-space: nowrap;
}

.deviceDetailsPropertiesLabel {
  flex: 1.5;
  text-align: left;
  font-size: 16px;
  font-weight: 700;
}

.deviceDetailsPropertiesData {
  flex: 2;
  text-align: left;
  font-size: 16px;
}
.deviceDetailsPropertiesData input {
  outline: none;
  border: none;
  padding: 10px;
  font-size: 16px;
  color: var(--font-color);
  border-radius: 10px;
  background-color: var(--light-button-color);
}
.deviceDetailsPropertiesData.inactive {
  color: red;
}
