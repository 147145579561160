.edgeStatistics {
  display: flex;
  width: 100%;
  gap: 10px;
  height: 100%;
}

.statisticsContainer {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 45%;
}
.topStatisticsContainer {
  display: flex;
  width: 100%;
  height: 100%;
}
.deviceStatistics {
  display: flex;
  width: 100%;
  height: 100%;
  gap: 15px;
  justify-content: center;
  margin: auto;
}
.deviceStatisticsContainer {
  display: flex;
  width: 100%;
  gap: 20px;
  justify-content: space-around;
  flex-direction: column;
}

.deviceStatisticsCard {
  display: flex;
  /* height: 30%; */
  justify-content: space-evenly;
}

.deviceStatisticsCardLeftContent {
  display: flex;
  flex-direction: column;
  width: 55%;
  height: 100%;
  margin-bottom: auto;
  margin-left: 15px;
  justify-content: space-between;
}

.deviceStatisticsCardHeading {
  display: flex;
  width: 100%;
  align-items: center;
  font-size: 14px;
  font-weight: 600;
  height: 40%;
}

.deviceStatisticsCardsStatusContainer {
  display: flex;
  flex-direction: column;
  height: 60%;
  gap: 5px;
  justify-content: center;
  overflow: scroll;
}

.deviceStatisticsStatus {
  display: flex;
}

.deviceStatisticsStatusDot {
  width: 10px;
  height: 10px;
  border-radius: 50%;
  margin-right: 10px;
  margin-top: auto;
  margin-bottom: auto;
  border: none;
}

.active {
  background-color: #35ba62;
}

.inactive {
  background-color: #f05d1e;
}

.deviceStatisticsCardRightContent {
  display: flex;
  flex-direction: column;
  width: 80%;
  height: 100%;
  margin-top: auto;
  margin-bottom: auto;
  justify-content: space-between;
  border-radius: 10%;
  text-align: center;
  margin-right: 15px;
  white-space: nowrap;
}

.deviceStatusPercentage {
  display: flex;
  height: 70%;
  align-items: flex-end;
  justify-content: flex-end;
  font-size: 30px;
  font-weight: 500;
  color: #35ba62;
  margin-right: 5px;
  text-align: right;
}

.deviceStatusText {
  margin: auto;
  font-weight: 600;
  color: #bcc4c3;
  margin-right: 20px;
}

.map-container {
  position: relative;
}

.mapcomponent {
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
  overflow: hidden;
}

.input-container {
  position: absolute;
  left: 20px;
  z-index: 1000;
  border-radius: 8px;
  padding: 10px;
  display: flex;
  align-items: center;
  margin-left: 3%;
}

.coordinate-input {
  width: 200px;
  padding: 8px;
  border: 1px solid #ccc;
  border-radius: 4px;
  margin-right: 8px;
  font-size: 14px;
}

.add-marker-button {
  padding: 8px; /* Adjust padding for uniform height */
  height: 38px; /* Set a fixed height */
  border: none;
  border-radius: 4px;
  background-color: #007bff;
  color: white;
  cursor: pointer;
  font-size: 14px;
  transition: background-color 0.3s;
  height: fit-content;
}

.add-marker-button:hover {
  background-color: #0056b3;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.3);
}

.location-button {
  margin-left: 10px;
  padding: 8px;
  height: 38px;
  width: 38px;
  border: none;
  border-radius: 50%;
  background-color: #007bff;
  color: white;
  cursor: pointer;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.2);
  transition: background-color 0.3s;
}

.location-button:hover {
  background-color: #0056b3;
}

.custom-gps-icon {
  text-align: center;
}

.custom-gps-icon img {
  width: 30px;
  height: 30px;
}

/* Hardware Statistics Styles */
.hardwareStatisticsContainer {
  display: flex;
  width: 100%;
  height: 100%;
  justify-content: space-around;
  flex-direction: column;
}

.hardwareStatisticsCardsContainer {
  display: flex;
  flex-direction: row;
  width: 100%;
  height: 100%;
  gap: 5px;
  justify-content: space-around;
}

.hardwareStatisticsCard {
  height: 100%;
  width: 32%;
}

.hardwareCardHeading {
  display: flex;
  width: 90%;
  height: 20%;
  margin-right: auto;
  padding: 5px;
}

.hardwareCardHeadingIcon {
  margin: auto;
}
.hardwareCardHeadingIcon.hardwareCardHeadingIconWrapper {
  display: flex;
  padding: 10px;
  border-radius: 50%;
  background-color: var(--primary-color);
}
.hardwareCardHeadingText {
  margin-right: auto;
  margin-top: auto;
  margin-bottom: auto;
  width: 55%;
  font-size: 11px;
  white-space: wrap;
}

.hardwareCardContent {
  display: flex;
  flex-direction: column;
  text-align: center;
  width: 85%;
  height: 70%;
  gap: 10px;
  margin: auto;
  border-radius: 16px;
  background-color: var(--edge-statistics-card-bg-color);
}

.utilizationPercentage {
  font-size: 25px;
  font-weight: 600;
  color: var(--font-color);
  margin-top: auto;
  position: fixed;
  bottom: 20px;
  left: 10%;
  right: 10%;
}

.utilizationText {
  position: fixed;
  font-size: 12px;

  font-weight: 500;
  color: var(--edge-statistics-utilization-text-color);
  bottom: 5px;
  left: 10%;
  right: 10%;
  white-space: nowrap;
}

.hardwareContentPercentage {
  transition: height 0.9s ease-in-out;
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-top: auto;
  backdrop-filter: blur(10px);
  background: linear-gradient(
    to bottom,
    rgba(81, 110, 92, 0),
    rgba(84, 245, 137, 0.6)
  );
  border-top: 2px solid rgba(84, 245, 137, 0.6);
  border-radius: 0px 0px 16px 16px;
}

.hardwareContentPercentage.zero {
  background-color: transparent;
}

.hardwareCardContent.memory {
  background-color: rgba(240, 93, 30, 0.06);
}

.hardwareContentPercentage.memory {
  backdrop-filter: blur(10px);
  background: linear-gradient(
    to bottom,
    rgba(240, 93, 30, 0),
    rgba(240, 93, 30, 0.6)
  );
  border-top: 2px solid rgba(240, 93, 30, 0.6);
}
.hardwareCardContent.cpuTemp {
  background-color: rgba(30, 124, 240, 0.06);
}
.hardwareContentPercentage.cpuTemp {
  backdrop-filter: blur(10px);
  background: linear-gradient(
    to bottom,
    rgba(240, 93, 30, 0),
    rgba(30, 107, 240, 0.6)
  );
  border-top: 2px solid rgba(30, 103, 240, 0.6);
}
.configurationStatisticsContainer {
  display: flex;
  align-items: flex-start;
  height: 100%;
  flex-direction: column;
  gap: 3px;
}

.statisticsHeading {
  font-size: var(--edge-statistics-heading-font-size);
  font-weight: var(--heading-font-weight);
  margin-bottom: 15px;
  margin-top: 15px;
  color: var(--font-color);
}

.configurationStatisticsCards {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  gap: 20px;
  height: 100%;
  justify-content: space-between;
}

.configurationStatisticsCard {
  width: 150px;
  cursor: pointer;
  padding-top: 35px;
  padding-bottom: 13px;
  color: var(--font-color);
}

.configurationStatisticsCard:hover {
  box-shadow: 0 0 3px rgba(0, 0, 0, 0.3);
  cursor: pointer;
}

.configurationContent {
  display: flex;
  cursor: pointer;
  justify-content: space-between;
  align-items: center;
  flex-direction: column;
  gap: 10px;
  position: relative;
  bottom: 20px;
}

.configurationHeading {
  font-size: 14px;
  font-weight: 600;
  color: var(--font-color);
}

.configurationCount {
  font-size: 2.3rem;
  font-weight: 700;
  color: #35ba62;
}

.edgeLink {
  text-decoration: none;
  color: inherit;
}

.divider {
  display: flex;
  flex-direction: row;
  gap: 15px;
}
.edgeStatisticsLeft {
  height: 100%;
  width: 50%;
  gap: 10px;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
}
.edgeStatisticsRight {
  width: 50%;
  display: flex;
  flex-direction: column;
}
