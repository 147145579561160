.context-menu {
  position: absolute;
  display: flex;
  flex-direction: column;
  top: 0;
  left: -110px;
  background-color: var(--dialog-color);
  backdrop-filter: var(--container-backdrop-filter);
  padding: 5px;
  width: 150px;
  z-index: 999;
  border-radius: 10px;
}
.context-menu button {
  display: block;
  cursor: pointer;
  border: none;
  margin-bottom: 5px;
  padding: 0.5em;
  text-align: center;
  width: 100%;
  border-radius: 10px;
}
