.rules {
  display: flex;
  position: relative;
  width: 100%;
  gap: var(--gap);
  height: 100%;
}
.rulesLeft {
  display: flex;
  gap: var(--gap);
  flex-direction: column;
}

.rulesConstantPopup {
  position: fixed;
  height: 80%;
  top: 10%;
  right: 1px;
  margin: auto;
  text-align: center;
  width: 20%;
  transform: translate(-1%, -50%);
  background-color: #fff;
  padding: 10px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);
  z-index: 999;
  border-radius: 20px;
}
.rulesPopupBtn {
  padding-left: 15px;
  padding-right: 15px;
  padding-top: 10px;
  padding-bottom: 10px;
  cursor: pointer;
  margin-right: 10px;
  color: white;
  font-weight: 600;
  border-radius: 20px;
}
.rulesPopupBtn.save {
  background-color: var(--light-green-color);
}
.rulesPopupBtn.cancel {
  background-color: var(--light-red-color);
}
.rulesSavePopup {
  position: fixed;
  top: 40%;
  right: 53%;
  width: 15%;
  transform: translate(-50%, -50%);
  background-color: #fff;
  padding: 10px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);
  z-index: 999;
  border-radius: 20px;
}

/* .rulesConstantPopup input[type="text"] {
  width: 80%;
  padding: 10px;
  margin-bottom: 50px;
  outline: none;
  border: 1px solid #928e8e;
  border-radius: 3px;
} */
.rulesSavePopup input[type="text"] {
  width: 90%;
  padding: 10px;
  margin-bottom: 10px;
  outline: none;
  border: 1px solid #928e8e;
  border-radius: 3px;
}

.rulesSavePopup button {
  padding: 10px 20px;
  margin-right: 10px;
  border: none;
  background-color: #000000;
  color: #fff;
  cursor: pointer;
  border-radius: 20px;
}

.rulesSavePopup button:hover {
  background-color: #29292a;
}
