.configurationSettingsNavigator {
  margin-top: 10px;
}

.configurationSettingsNetworkOptions {
  display: flex;
  gap: 10px;
}
.saveNetworkConfigurations {
  display: flex;
  width: fit-content;
  margin: auto;
  border-radius: 10px;
  padding: 10px;
  cursor: pointer;
  background-color: var(--light-heading-color);
  color: var(--light-font-color);
}

.mqttCertificate {
  display: flex;
  width: 100%;
  height: 100%;
  margin-top: 10px;
  gap: 10px;
  overflow: scroll;
}
.mqttCertificateForm {
  display: flex;
  flex-direction: column;
  width: 50%;
  height: 98%;
  gap: 20px;
}
.mqttCertificateGuide {
  display: flex;
  flex-direction: column;
  width: 50%;
  height: 96%;
  border-left: var(--container-border);
}
.certificateInfoContainer {
  display: flex;
  flex-direction: column;
  text-align: left;
  width: 90%;
  margin-top: 10px;
  margin-left: auto;
  margin-right: auto;
  font-size: var(--heading-font-size);
  font-weight: 300;
}
.certificateInfoHeading {
  font-size: var(--custom-header-font-size);
  font-weight: var(--heading-font-weight);
  margin-bottom: 10px;
}
.certificateInfoContent {
  display: flex;
  gap: 10px;
  flex-direction: column;
}
.certificateInfoContentList {
  display: flex;
  flex-direction: column;
  gap: 15px;
}
.certificateInfoContentList li {
  list-style: decimal;
}

.secretKeyInputGroup {
  display: flex;
  width: 80%;
  justify-content: flex-start;
  align-items: flex-start;
  margin-left: auto;
  margin-right: auto;
  flex-direction: column;
}
.secretKeyInputGroup label {
  margin-bottom: 5px;
}
.secretKeyInputGroup input {
  width: 95%;
  background-color: var(--container-background-color);
  border: var(--container-border);
  border-radius: var(--input-border-radius);
  color: var(--font-color);
  padding: 10px;
}
.copyInputField {
  width: 100%;
  display: flex;
  gap: 5px;
}

.mqttCertificateFormContent {
  display: flex;
  flex-direction: column;
  gap: 10px;
}
.certificateApexDevicesContainer {
  display: flex;
  flex-direction: column;
  width: 80%;
  height: 55%;
  margin-left: auto;
  margin-right: auto;
  gap: 20px;
}
.apexDevicesHeader {
  display: flex;
  align-items: center;
  gap: 10px;
  font-size: var(--custom-header-font-size);
  text-align: left;
  font-weight: var(--heading-font-weight);
}
.apexDeviceContainer {
  display: flex;
  flex-direction: column;
  background-color: var(--container-background-color);
  height: 90%;
  overflow: scroll;
  padding: 5px;
  border-radius: var(--container-border-radius);
  border: var(--container-border);
}
.apexDeviceItem {
  display: flex;
  flex-direction: row;
  width: 90%;
  padding-left: 15px;
  gap: 10px;
  padding-right: 15px;
  align-items: center;
  border-radius: var(--container-border-radius);
  border: var(--container-border);
  background-color: var(--container-background-color);
  margin-left: auto;
  margin-right: auto;
  align-items: flex-start;
}
.apexDeviceItem span {
  margin: auto;
  width: 50px;
}

.apexDeviceSecretKey {
  width: 40%;
  overflow: scroll;
}
.mqttCertificateBtns {
  display: flex;
  margin-left: auto;
  margin-right: auto;
  gap: 10px;
}
