.pointDetailsConfigNavigator {
  margin-top: 10px;
}
.pointDetailsConfig {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  margin-top: auto;
  align-items: center;
  justify-content: flex-end;
}
.pointDetailsBtnContainer {
  display: flex;
  margin-left: auto;
  margin-right: auto;
  width: 100%;
  justify-content: center;
}
