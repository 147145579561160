.alarms {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  gap: var(--gap);
}
.alarms.dark {
  background-color: var(--dark-secondary-color);
}
.alarmBtns {
  width: 100%;
  display: flex;
  justify-content: center;
  gap: 40px;
}
.alarmBtn {
  background-color: var(--heading-details-color);
  color: var(--light-font-color);
  padding-left: 15px;
  padding-right: 15px;
  padding-top: 5px;
  padding-bottom: 5px;
  font-weight: 600;
  border-radius: var(--button-border-radius);
  cursor: pointer;
  border: none;
}
.alarmBtn.dark {
  color: var(--black-color);
}
.alarmTableMessage {
  display: flex;
  border: none;
  outline: none;
  border-radius: 10px;
  background-color: var(--item-selected-color);
  padding: 6px;
  justify-content: space-between;
  border: 1px solid black;
}
.alarmTableMessage input {
  outline: none;
  border: none;
  flex-grow: 1;
  max-width: 80%;
}
.userTagText {
  width: 90%;
}
.userTagRemoveBtn {
  width: 10%;
  background-color: red;
  color: black;
}
.alarmTableMessage span {
  background-color: var(--input-background-color);
  border-radius: var(--button-border-radius);
  padding: 5px;
  margin: auto;
  cursor: pointer;
}
.tagUserDialog {
  display: flex;
  flex-direction: column;
  width: 20%;
  height: 30%;
  overflow: scroll;
  background-color: var(--white-color);
  border-radius: var(--container-border-radius);
  padding: 10px;
}
.tagUserHeading {
  display: flex;
  width: 100%;
  text-align: center;
  justify-content: center;
  font-weight: 700;
  font-size: 16px;
}
.usersList {
  display: flex;
  width: 100%;
  background-color: var(--white-color);
  border-radius: var(--button-border-radius);
  margin: auto;
  margin-top: 10px;
  height: 80%;
  overflow: scroll;
  flex-direction: column;
  cursor: pointer;
}
.usersList span {
  padding: 10px;
  width: 80%;
  text-align: center;
  margin-left: auto;
  margin-right: auto;
  border-radius: var(--button-border-radius);
}
.selectedUser {
  background-color: var(--input-background-color);
}
.alarmTableAckStatus select {
  border: none;
  outline: none;
  background-color: var(--item-selected-color);
  padding: 6px;
  border: 1px solid black;
}
.tagUserBtn {
  justify-content: flex-end;
}
.userTagBtns {
  display: flex;
  width: 60%;
  margin-left: auto;
  margin-right: auto;
}
.tagUser {
  display: flex;
  width: 100%;
  height: 100%;
  margin: auto;
  justify-content: center;
  align-items: center;
}

.alarmTableHeader {
  white-space: nowrap;
  text-align: center;
}

.alarmsHeading {
  font-size: var(--heading-size);
  width: 100%;
  margin-bottom: 40px;
  color: var(--header-color);
  margin-top: 25px;
  text-align: center;
}
