.newFolderItem {
  display: flex;
  width: 90%;
  height: fit-content;
  align-items: center;
  gap: 5px;
  margin-left: auto;
  margin-right: auto;
}
.folderItem {
  width: 95%;
  margin-left: auto;
  margin-right: auto;
  align-items: center;
  gap: 5px;
  padding: 5px;
  display: flex;
  justify-content: space-between;
}
.normalfolderItem {
  width: 95%;
  margin-left: auto;
  margin-right: auto;
  align-items: center;
  gap: 5px;
  padding: 5px;
  display: flex;
  justify-content: left;
}
.checkFieldsNewFolder {
  display: flex;
  flex-direction: column;
  height: 60%;
}
.folderViewContent {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 78vh;
  margin-left: auto;
  margin-right: auto;
  margin-top: 10px;
  gap: 16px;
  overflow-y: scroll;
  overflow-x: scroll;
  /* overflow: scroll !important; */
}
.folderViewContent::-webkit-scrollbar {
  width: 0px;
}
.folderViewContent::-webkit-scrollbar:horizontal {
  display: flex !important;
  height: 7px;
}

.folderViewContent::-webkit-scrollbar-track {
  background: var(--container-background-color);
  border-radius: var(--container-border-radius);
  border: var(--container-border);
}
.folderViewContent::-webkit-scrollbar-thumb {
  border-radius: 20px;
  background: #555;
}
.folderViewContent::-webkit-scrollbar-thumb:hover {
  background: var(--font-color);
}
.leftFolderSection {
  width: 70%;
  display: flex;
  align-items: center;
}
.expandCollapseBtn {
  display: flex;
  align-items: center;
  margin-top: auto;
  margin-bottom: auto;
}
.rightFolderSection {
  display: flex;
  gap: 2px;
  width: 25%;
}
.pointsList {
  display: flex;
  flex-direction: column;
  gap: 5px;
  margin-top: 5px;
  width: 80%;
  margin-left: auto;
  margin-right: auto;
}
.pointItem {
  padding: 3px;
}
.folderNameInput {
  display: flex;
  width: 80%;
  background-color: var(--container-background-color);
  outline: none;
  border-radius: 3px;
  border: var(--container-border);
  color: var(--font-color);
  padding: 2px;
  backdrop-filter: var(--container-backdrop-filter);
}
.folderInput {
  display: flex;
  gap: 5px;
  align-items: center;
  cursor: pointer;
}
.folderInput span {
  font-size: var(--heading-font-size);
  font-weight: var(--heading-font-weight);
}
.folderTemplateContainer {
  display: flex;
  flex-direction: column;
  margin-top: 20px;
}
