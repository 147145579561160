.widgetConfigurationForm {
  display: flex;
  flex-direction: column;
  position: absolute;
  width: 25%;
  height: 97%;
  right: 0;
}
.configurationFormContentHeading {
  display: flex;
  width: 100%;
  height: 10%;
  justify-content: center;
  margin-bottom: auto;
  align-items: center;
}
.configurationFormContents {
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
}
.configurationForm {
  height: 90%;
  width: 90%;
  margin-left: auto;
  margin-right: auto;
  margin-top: 10px;
  display: flex;
  flex-direction: column;
  margin-bottom: auto;
  overflow: scroll;
}
.configurationFormContentHeading span {
  width: 100%;
  text-align: center;
  font-size: var(--custom-header-font-size);
  font-weight: var(--heading-font-weight);
}
.configurationFormBtns {
  justify-content: center;
  margin-top: auto;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 10px;
  gap: 5px;
  width: 80%;
  display: flex;
}
.chooseImgBtn {
  display: flex;
  width: 90%;
  height: fit-content;
  padding: 10px;
  justify-content: center;
  font-size: 12px;
  border-radius: 8px;
}
.imagePreviewContainer {
  position: relative;
  width: fit-content;
}
.configurationFormDeleteIcon {
  position: absolute;
  cursor: pointer;
  color: var(--light-red-color);
  font-size: 30px;
  top: 0;
}
.widgetDisplay {
  display: flex;
  width: 95%;
  margin: auto;
  align-items: center;
}
.widgetGroupInput {
  display: flex;
  width: 100%;
}
.widgetGroupInput span {
  width: fit-content;
  margin-top: auto;
  margin-bottom: auto;
  margin-left: 0;
  margin-right: 0;
}
