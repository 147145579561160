.mathBlockHeader {
  padding: 10px;
  font-weight: var(--heading-font-weight);
  font-size: var(--custom-header-font-size);
  text-align: center;
}
.mathBlockContent {
  display: flex;
  width: 100%;
  height: 80vh;
  overflow: scroll;
  margin-top: 20px;
  text-align: center;
}
.mathBlocks {
  display: flex;
  width: 100%;
  text-align: left;
  gap: 10px;
  margin-left: 60px;
  flex-direction: column;
  background-color: none;
}
.mathBlockOperator {
  font-weight: 600;
  cursor: pointer;
}
