.customTableContent {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
  height: 100%;
  background-color: var(--container-background-color);
  backdrop-filter: var(--container-backdrop-filter);
  border-radius: var(--container-border-radius);
  overflow: hidden;
}
.customTable {
  overflow-y: auto;
}
.customTableHeader {
  margin-left: 5px;
  cursor: pointer;
}
.customTable table {
  width: 100%;
  height: 100%;
  border-collapse: collapse;
}

.customTable thead th {
  position: sticky;
  padding-top: 15px;
  padding-bottom: 15px;
  top: 0;
  background-color: var(--table-header-color);
  backdrop-filter: blur(100px);
  z-index: 2;
  text-align: left;
  border: none;
  outline: none;
  font-size: var(--heading-font-size);
  font-weight: var(--heading-font-weight);
  color: var(--font-color);
}

.firstChild {
  border-radius: 16px 0px 0 0;
}
.lastChild {
  border-radius: 0 16px 0 0;
}

/* .customTable tbody tr {
  cursor: pointer;
} */

.customTable tbody tr:hover {
  background-color: var(--container-background-color);
}

.customTable td,
.customTable th {
  padding: 10px;
  text-align: left;
  border-bottom: var(--table-bottom-border);
}

.customTableFooter {
  display: flex;
  align-items: center;
  padding: 15px;
  justify-content: space-between;
}
.rowCount {
  font-size: var(--heading-font-size);
  font-weight: var(--heading-font-weight);
  text-align: left;
}
.pagination {
  display: flex;
  gap: 5px;
  font-size: var(--heading-font-size);
  font-weight: var(--heading-font-weight);
}
.paginationBtn {
  padding: 5px;
  border-radius: 5px;
}
.pageNumber {
  padding: 5px;
  border-radius: 5px;
  background-color: var(--container-background-color);
  color: var(--font-color);
  backdrop-filter: var(--container-backdrop-filter);
}
.activePage {
  padding: 5px;
  border-radius: 5px;
  background-color: var(--selected-nav-bg-color);
  color: var(--font-color);
  backdrop-filter: var(--container-backdrop-filter);
}

.rowsPerPage {
  display: flex;
  gap: 5px;
  justify-content: center;
  align-items: center;
}
.rowsPerPage select {
  display: flex;
  background-color: var(--container-background-color);
  border: var(--container-border);
  border-radius: var(--input-border-radius);
  color: var(--font-color);
  padding: 5px;
  height: fit-content;
  width: fit-content;
}
.customTable .fixedColumn {
  position: sticky;
  left: 0;
  z-index: 999;
  background-color: var(--table-header-color);
  backdrop-filter: blur(100px);
}
.alarmState {
  padding-top: 5px;
  padding-bottom: 5px;
  border-radius: var(--container-border-radius);
  display: flex;
  align-items: center;
  justify-content: center;
  margin: auto;
}
.alarmState.alarm {
  background-color: #ebb3b3;
  color: black;
}
.alarmState.normal {
  background-color: #49915f;
  color: white;
}
