.settingsNavigator {
  gap: 16px;
  height: 100%;
  width: 100%;
}

.settingsNavigatorHeader {
  display: flex;
  height: 36px;
  font-weight: 600;
  text-align: center;
  justify-content: center;
  align-items: center;
  margin: auto;
}
.settingsNavigatorHeader span {
  font-size: var(--custom-header-font-size);
  font-weight: var(--custom-header-font-weight);
  color: var(--font-color);
}

.settingsNavigatorOptions {
  margin-top: 20px;
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 10px;
  margin-left: auto;
  margin-right: auto;
  justify-content: center;
  align-items: center;
}
.settingsNavigatorOption {
  cursor: pointer;
  display: flex;
  flex-direction: row;
  border-radius: var(--button-border-radius);
  padding: 5px;
  width: 80%;
}
.settingsNavigatorOption.selected {
  background-color: var(--settings-selected-color);
  color: var(--font-color);
  width: 100%;
  margin-left: 35px;
}
.settingsNavigatorOption.selected.dark {
  background-color: white;
}
.settingsNavigatorOptionIcon {
  margin-right: 10px;
}
