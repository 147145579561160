.textWidget {
  display: flex;
  width: fit-content;
  text-align: center;
  height: 100%;
  /* font-size: 16px; */
  align-items: center;
  margin: auto;
  font-weight: 600;
  text-align: left;
  overflow: scroll;
}
