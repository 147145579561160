.pointDetailsTrendsTableContent {
  display: flex;
  flex-direction: column;
  gap: 5px;
  width: 100%;
  height: 100%;
}
.pointDetailsAlarmsChart {
  height: 100%;
}
.pointDetailsTable {
  max-height: 60vh;
  overflow-y: auto;
}

.pointDetailsTable table {
  width: 100%;
  height: 100%;
  border-collapse: collapse;
}

.pointDetailsTable thead th {
  position: sticky;
  top: 0;
  background-color: var(--table-header-color);
  backdrop-filter: blur(100px);
  z-index: 2;
  padding: 20px;
  text-align: left;
  border: none;
  outline: none;
  font-size: var(--heading-font-size);
  font-weight: var(--heading-font-weight);
  color: var(--font-color);
}

.firstChild {
  border-radius: 16px 0px 0 0;
}
.lastChild {
  border-radius: 0 16px 0 0;
}

.pointDetailsTable tbody tr {
  cursor: pointer;
}

.pointDetailsTable tbody tr:hover {
  background-color: var(--container-background-color);
}

.pointDetailsTable td,
.pointDetailsTable th {
  padding: 20px;
  text-align: left;
  border-bottom: var(--table-bottom-border);
}
.pointDetailTrendsBtns {
  position: absolute;
  display: flex;
  gap: 5px;
  top: 16%;
  right: 1.5%;
}
.export-btn-style {
  margin-top: -8px;
}
.pointTrendChartContainer {
  display: flex;
  width: 100%;
  height: 100%;
  margin: auto;
}
