.assetInfo {
  display: flex;
  flex-direction: column;
  gap: 20px;
  width: 100%;
  height: 100%;
}
.assetTitle {
  display: flex;
  width: 85%;
  margin-top: 3px;
  margin-left: auto;
  margin-right: auto;
  font-weight: var(--heading-font-weight);
  font-size: var(--custom-header-font-size);
}
.assetContent {
  display: flex;
  flex-direction: column;
  gap: 25px;
  font-size: var(--heading-font-size);
  width: 85%;
  overflow: scroll;
  margin-top: 30px;
  margin-left: auto;
  margin-right: auto;
}
.assetStatus {
  padding-left: 10px;
  padding-top: 5px;
  color: var(--black-color);
  padding-right: 10px;
  padding-bottom: 5px;
  border-radius: var(--container-border-radius);
}
.assetStatus.running {
  background-color: var(--light-green-shade);
}
.assetStatus.stopped {
  background-color: var(--light-red-shade);
}
.assetCard {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
