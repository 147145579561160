.miniWidgets {
  display: flex;
  flex-direction: column;
  width: fit-content;
  height: 100%;
  margin: auto;
}
.miniWidgetMenu {
  display: flex;
  flex-direction: column;
  margin-left: auto;
  margin-right: auto;
  padding: 5px;
  text-align: center;
  gap: 5px;
}

.miniWidgetIcon {
  padding-left: 10px;
  padding-right: 10px;
  padding-top: 5px;
  padding-bottom: 5px;
  border-radius: 15px;
  outline: none;
  cursor: pointer;
}

.miniWidgetIcon.dark {
  background-color: #d1e5fb;
  color: black;
}
