/* @import url("https://fonts.googleapis.com/css2?family=Inter:wght@100..900&family=Monoton&family=Protest+Guerrilla&display=swap"); */
@import url("https://fonts.googleapis.com/css2?family=Bungee+Inline&family=Inter:wght@100..900&family=Monoton&family=Protest+Guerrilla&family=Rye&display=swap");

.pointDetailsSummaryLiveDataContainer {
  display: flex;
  flex-direction: column;
  border: var(--container-border);
  border-radius: var(--container-border-radius);
  background-color: var(--container-background-color);
  width: 100%;
  justify-content: center;
  align-items: center;
  margin: auto;
  height: 70vh;
}
.pointDetailsSummaryLiveDataContents {
  display: flex;
  flex-direction: column;
  margin: auto;
  align-items: center;
  margin-top: 10px;
  height: 100%;
  font-size: 14px;
  width: 100%;
}
.pointDetailsSummaryLiveDataContent {
  width: 100%;
  margin-bottom: 5px;
  overflow: scroll;
  margin-bottom: 10px;
  text-align: center;
}
/* @import url("https://db.onlinewebfonts.com/c/312210662b25f216a29c90d0d150d4cc?family=Brugty+DEMO+Regular"); */
@import url("https://fonts.cdnfonts.com/css/library-3-am");
.liveValueContainer {
  /* font-family: "Protest Guerrilla", sans-serif; */
  font-family: "LIBRARY 3 AM", sans-serif;
  width: 100%;
  font-style: normal;
  justify-content: center;
  align-items: center;
  margin-top: auto;
  text-align: center;
  font-size: 40px;
  font-weight: 800;
  overflow: scroll;
  text-transform: uppercase;
}

/* .liveValueContainer {
  font-family: "Roboto", sans-serif;
  font-weight: 750;
  font-style: normal;
  font-size: 40px;
  margin-top: 40px;
  overflow: scroll;
  text-transform: uppercase;
} */

.liveWidgetLastPart {
  display: flex;
  flex-direction: column;
  margin-top: auto;
  width: 100%;
}
.liveValueContainer.red {
  color: var(--light-red-color);
}

.liveValueContainer.green {
  color: var(--light-green-color);
}
.liveValueContainer.yellow {
  color: var(--light-yellow-color);
}

.liveDataWidetChart {
  width: 90% !important;
  margin: auto;
}
.pointDetailsSummaryLiveDataHeading {
  padding: 10px;
  width: 95%;
  color: var(--font-color);
  font-size: var(--custom-header-font-size);
  font-weight: var(--custom-header-font-weight);
  justify-content: center;
  text-align: center;
}
.pointDetailsSummaryLiveDataInputGroup {
  display: flex;
  flex-direction: column;
  padding-bottom: 10px;
}
.pointDetailsSummaryLiveDataInputGroupLabel {
  display: flex;
  flex-direction: column;
  margin: auto;
  font-size: 13px;
  gap: 10px;
  text-align: left;
  width: 70%;
  margin-top: 20px;
}
.pointDetailsSummaryLiveDataInputGroupInput {
  display: flex;
  flex-direction: column;
  width: 45%;
  gap: 5px;
}
.pointDetailsSummaryLiveDataInputGroupInput input {
  width: 80%;
  justify-content: start;
  outline: none;
  background-color: var(--item-selected-color);
  border: none;
}
.pointDetailsSummaryLiveDataInputGroupInput select {
  width: 85%;
  justify-content: start;
  outline: none;
  padding: 0px;
  background-color: var(--item-selected-color);
  border: none;
}
.Writableflag {
  background-color: var(--container-background-color);
  color: var(--font-color);
  margin: auto;
  width: 100px;
  padding: 8px;
  text-align: center;
  margin-top: 10px;
  margin-bottom: 20px;
  justify-content: center;
  border-radius: var(--button-border-radius);
}

.pointDetailsLiveDataHeading {
  font-size: 12px;
  color: rgb(136, 127, 127);
}

.labelInputContainer {
  width: 100%;
  display: flex;
  flex-direction: column;
  border-radius: var(--button-border-radius);
  margin-bottom: 10px;
  align-items: flex-start;
}
.labelInputContainer label {
  text-align: left;
}

.labelInputContainer input,
.labelInputContainer select {
  padding-top: 8px;
  padding-bottom: 8px;
  margin-top: 5px;
  text-align: center;
}

.modeToggle {
  display: flex;
  margin-top: 8px;
  width: 100%;
  gap: 5px;
}
.toggleButton {
  padding: 8px 16px;
  color: var(--font-color);
  cursor: pointer;
  background-color: transparent;
  border: var(--container-border);
  border-radius: var(--selected-border-radius);
  transition: background-color 0.3s, color 0.3s;
  width: 150px;
}
.toggleButton.notactive {
  background-color: transparent;
}
.toggleButton.active {
  background: var(--selected-nav-bg-color);
  border-radius: var(--selected-border-radius);
  border: 1px solid rgba(255, 255, 255, 0.15);
  backdrop-filter: blur(100px);
}

.toggleButton:disabled {
  background-color: transparent !important;
  color: var(--disabled-button-text-color);
  cursor: not-allowed !important;
  border: 0.2px solid rgb(190, 186, 186);
}
