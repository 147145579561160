.widgets {
  display: flex;
  flex-direction: column;
  max-width: 14%;
  height: 100%;
  justify-content: space-between;
}

.widgetsHeader {
  display: flex;
  text-align: center;
  font-size: 16px;
  font-weight: var(--custom-header-font-weight);
  justify-content: center;
  margin: auto;
  align-items: center;
}

.widgetsContent {
  display: flex;
  flex-wrap: wrap;
  overflow: scroll;
  gap: 10px;
  justify-content: center;
  align-items: center;
  height: 89%;
  width: 93%;
  margin: auto;
}
.widgetsContainer {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  justify-content: center;
}
.widget {
  justify-items: center;
  align-items: center;
  width: 80px;
  height: 80px;
  display: flex;
  background-color: var(--container-background-color);
  border-radius: var(--container-border-radius);
  cursor: pointer;
  flex-direction: column;
  border: var(--container-border);
}
.widget.dark {
  background-color: var(--dark-background-color);
}
.widget p {
  margin: auto;
  font-size: 12px;
}

.widgetIcon {
  margin: auto;
}
.preBuiltDisplayNavigator {
  display: flex;
  flex-direction: column;
  width: 15%;
  height: 100%;
}
.preBuiltDisplays {
  width: 15%;
  height: 100%;
  border-radius: 20px;
  background-color: #eaf1fb;
}
.prebuiltDisplayHeader {
  background-color: #c2e7ff;
  padding: 10px;
  font-weight: 600;
  text-align: center;
  border-radius: 20px;
}
.prebuiltDisplayHeader span {
  font-size: 12px;
}
.prebuiltDisplayHeader.dark {
  background-color: #d1e5fb;
  color: var(--dark-background-color);
}
.prebuiltDisplayContent {
  display: flex;
  flex-direction: column;
  height: 80%;
  margin-top: 10px;
  overflow: scroll;
  align-items: center;
}
.prebuiltDisplayContent div {
  cursor: pointer;
  padding: 5px;
  text-align: center;
}
.displayItem.selected {
  background-color: var(--light-heading-color);
  border-radius: 10px;
  width: 80%;
}

.floorWidgets {
  display: flex;
  border-radius: var(--container-border-radius);
}
.widgetsLibrary {
  width: 40%;
  justify-content: start;
}
.widgetsLibraryContent {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  margin-left: auto;
  margin-right: auto;
  justify-content: flex-start;
}
.widgetsLibraryWidget {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 10px;
  align-items: center;
  width: 90%;
  height: 10%;
}
.checkboxGroup {
  display: flex;
  width: 90%;
  margin: auto;
  margin-top: 15px;
  margin-bottom: 15px;
}
.checkboxGroupLabel {
  display: flex;
  width: 90%;
  font-size: 12px;
  font-weight: var(--heading-font-weight);
}
.accordion-item {
  display: flex;
  flex-direction: column;
  width: 100%;
}
.accordion-header {
  display: flex;
  height: fit-content;
  cursor: pointer;
  justify-content: space-between;
  align-items: center;
  padding: 10px;
  border-radius: 10px;
  background-color: var(--container-background-color);
  box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
}
.categoryName {
  display: flex;
  width: 90%;
}
.accordion-content {
  /* display: flex; */
  /* flex-direction: column; */
  max-height: 50vh;
  overflow: scroll;
}
.widgetsLibraryContainer {
  display: flex;
  flex-direction: column;
  gap: 10px;
}
