.trends {
  display: flex;
  flex-grow: 1;
  width: 100%;
  gap: 20px;
  height: 100%;
}
.trendsCanvas {
  display: flex;
  justify-content: center;
  flex-direction: column;
  gap: var(--gap);
  flex-grow: 1;
  height: 100%;
}
.trendsSidebar {
  display: flex;
  flex-direction: column;
  gap: 10px;
  height: 100%;
}
