.ruleCanvas {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  text-align: center;
  align-items: center;
  justify-content: center;
}
.react-flow__attribution a {
  display: none;
}
.ruleCanvasSaveBtn {
  padding-left: 8px;
  padding-right: 8px;
  padding-top: 6px;
  padding-bottom: 6px;
  margin-top: 160px;
  background-color: white;
  color: var(--black-color);
  border-radius: 10px;
  cursor: pointer;
}

.ruleCanvasSaveBtn:hover {
  background-color: #f3f3f3;
}
.ruleCanvasClearNodesBtn {
  padding-left: 5px;
  padding-right: 5px;
  padding-top: 6px;
  padding-bottom: 6px;
  margin-top: 10px;
  background-color: white;
  color: var(--black-color);
  border-radius: 10px;
  cursor: pointer;
}
.ruleCanvasClearNodesBtn:hover {
  background-color: #f3f3f3;
}
.react-flow__controls-button {
  padding: 5px;
  margin-top: 10px;
  background-color: white;
  color: var(--font-color) !important;
  border-radius: 10px;
  cursor: pointer;
}
.ruleCanvasControls {
  border: none;
  background-color: transparent;
  outline: none;
  box-shadow: none;
}
.ruleCanvasTopOptions {
  width: 100%;
  margin-top: 10px;
  display: flex;
  justify-content: space-between;
}
.ruleCanvasRuleName {
  display: flex;
  width: 20%;
  gap: 5px;
}

.ruleCanvasSwitch {
  display: flex;
  width: fit-content;
  height: fit-content;
  font-size: 14px;
  margin-left: 10px;
  margin-bottom: -10px;
  margin-top: -10px;
}
.ruleCanvasSwitch p {
  padding-left: 15px;
  width: fit-content;
  padding-right: 15px;
  border-radius: 20px;
}

.ruleCanvasRuleName p {
  padding-left: 15px;
  width: fit-content;
  padding-right: 15px;
  padding-top: 10px;
  padding-bottom: 10px;
  border-radius: 10px;
}
.react-flow__node.selected {
  border-color: var(--font-color);
  border: 1px solid;
  background-color: transparent !important;
  border-radius: 10px;
}
.react-flow__edge.selected {
  color: var(--font-color);
}

.minimap {
  border-radius: 10px !important;
}
.minimap > svg * {
  stroke: none !important;
  fill: var(--container-background-color) !important;
}
.minimap > rect * {
  background-color: black;
}
.react-flow__minimap-mask {
  display: none !important;
}
