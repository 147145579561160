.pointDetailsAlarms {
  display: flex;
  flex-direction: column;
  height: 100%;
  flex-grow: 1;
  width: 100%;
  justify-content: flex-end;
}

.saveButtonAlarmProperties {
  cursor: pointer;
  width: fit-content;
}
.pointDetailsAlarmsContent {
  display: flex;
  flex-grow: 1;
  justify-content: center;
  margin: auto;
  width: 100%;
  height: 100%;
  align-items: center;
}
.pointDetailsAlarmsPartContent {
  width: 50%;
}
.pointDetailsAlarmsGroup {
  display: flex;
  margin: 0 auto;
}

.pointDetailsAlarmsLabel {
  width: 80%;
  text-align: left;
  font-size: 16px;
  font-weight: 700;
  margin-bottom: 10px;
  margin-top: 10px;
  margin-left: 14px;
}

.pointDetailsAlarmsData {
  text-align: center;
  display: flex;
  outline: none;
  padding: 5px;
  border-radius: 10px;
  font-size: 16px;
  border: none;
  background-color: var(--light-button-color);
}
.pointDetailsAlarmsData.select {
  width: 210px;
}
.pointDetailsSaveBtn {
  display: flex;
  width: fit-content;
  margin: auto;
  cursor: pointer;
  margin-left: 30%;
  padding-left: 15px;
  padding-top: 10px;
  padding-bottom: 10px;
  padding-right: 15px;
  border-radius: var(--button-border-radius);
  font-weight: 500;
  color: var(--light-font-color);
  background-color: var(--light-heading-color);
}
