.displayNavigator {
  height: 100%;
  width: 33vh;
  flex-direction: column;
  display: flex;
}

.displayNavigatorHeader {
  display: flex;
  align-items: center;
  width: 90%;
  justify-content: space-between;
  margin: auto;
  gap: 5px;
}
.displayNavigatorHeader span {
  font-size: 16px;
  font-weight: var(--custom-header-font-weight);
  align-items: center;
}
.displayNavigatorHeaderBtns {
  display: flex;
  gap: 3px;
}
.displayNavigatorContentLists {
  display: flex;
  flex-direction: column;
  width: 90%;
  gap: 5px;
  margin-left: auto;
  margin-right: auto;
}
.displayNavigatorContentList {
  display: flex;
  width: 100%;
  justify-content: space-between;
  padding: 5px;
  gap: 5px;
  text-align: center;
  align-self: center;
  justify-self: center;
  margin-top: auto;
  margin-bottom: auto;
}

.displayNavigatorContentDeviceList {
  display: flex;
  width: 90%;
  height: 100%;
  padding: 5px;
  list-style: none;
  text-align: center;
  margin-left: 5px;
  justify-content: flex-end;
  align-items: flex-end;
}
.displayNavigatorContentListBtns {
  display: flex;
  flex-direction: row;
  width: 25%;
  gap: 2px;
  margin: auto;
  justify-content: flex-end;
}
.displayNavigatorContentList span {
  display: flex;
  width: 80%;
  text-align: left;
  height: 100%;
  cursor: pointer;
  align-items: center;
  margin-top: auto;
  margin-bottom: auto;
}
.displayNavigatorContent {
  display: flex;
  height: 90%;
  width: 90%;
  overflow: scroll;
  margin: auto;
  flex-direction: column;
  align-items: center;
}
.displayNavigatorPrebuiltDisplays {
  display: flex;
  width: 90%;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.displayNavigatorPrebuiltDisplay {
  border-radius: 20px;
  display: flex;
  gap: 10px;
  width: fit-content;
  margin-left: 20px;
  margin-top: 5px;
  margin-bottom: 5px;
}
.displayNavigatorPrebuiltDisplay li {
  text-align: left;
}
.displayNavigatorPrebuiltDisplaysList {
  display: flex;
  flex-direction: column;
  width: 100%;
}
.displayNavigatorPreBuiltDisplayDevices {
  display: flex;
  flex-direction: column;
  margin-left: 50px;
  justify-content: center;
  width: fit-content;

  text-align: center;
}
.select {
  background-color: var(--selected-color);
  border-radius: var(--button-border-radius);
  padding: 5px;
}
.displayItem .select {
  background-color: var(--selected-color);
  margin-top: 2px;
  border-radius: var(--button-border-radius);
}

.canvasClearBtn {
  display: flex;
  margin-top: 10px;
  flex-direction: row;
  background-color: var(--light-heading-color);
  color: var(--light-font-color);
  height: fit-content;
  width: fit-content;
  padding-top: 5px;
  padding-bottom: 5px;
  cursor: pointer;
  padding-left: 15px;
  padding-right: 15px;
  font-weight: 600;
  font-size: 16px;
  gap: 10px;
  border-radius: 10px;
}
.canvasClearBtn p {
  margin: auto;
}
.canvasClearBtnIcon {
  margin: auto;
  font-weight: 600;
}
.canvasClearBtn.dark {
  color: var(--black-color);
}

.expandCollapseIcon {
  display: flex;
  cursor: pointer;
  margin-top: auto;
  margin-bottom: auto;
}
