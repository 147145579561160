.pointsDetailsProperties {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 88%;
  align-items: center;
}
.pointsDetailsPropertiesContent {
  display: flex;
  gap: 20px;
  width: 100%;
  height: 90%;
  overflow: scroll;
}

.saveButtonProperties {
  background-color: var(--heading-details-color);
  padding-left: 15px;
  padding-right: 15px;
  padding-top: 8px;
  padding-bottom: 8px;
  font-size: 14px;
  cursor: pointer;
  color: var(--light-font-color);
  border-radius: 8px;
  font-weight: 600;
  width: -moz-fit-content;
  width: fit-content;
}

.pointsDetailsPropertiesPartContent {
  display: flex;
  flex-direction: column;
  width: 50%;
}
.pointsDetailsPropertiesGroup {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.pointsDetailsPropertiesLabel {
  text-align: left;
  font-size: 16px;
  width: 40%;
  font-weight: 700;
}

.pointsDetailsPropertiesData {
  /* flex: 2; */
  width: 80%;
  display: flex;
  align-items: center;
  text-align: left;
  font-size: 16px;
}
.pointsDetailsPropertiesData input {
  outline: none;
  border: none;
  padding: 10px;
  font-size: 16px;
  color: var(--font-color);
  border-radius: 10px;
  background-color: var(--light-button-color);
}
.pollFrequency {
  outline: none;
  width: 10%;
  text-align: center;
  border-radius: 10px;
  background-color: var(--light-button-color);
  border: none;
  padding: 10px;
  margin-right: 10px;
}
input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  appearance: none;
  margin: 0;
}
.pointsDetailsPropertiesBtnGroup {
  display: flex;
  width: 100%;
  justify-content: center;
  margin-top: auto;
}
.pointDetailsPropertiesSaveButton {
  padding: 10px;
  width: 10%;
  margin-left: auto;
  margin-right: auto;
  margin-top: 20px;
  border-radius: var(--button-border-radius);
  color: var(--light-font-color);
  background-color: var(--light-heading-color);
  cursor: pointer;
}
.deviceDetailsNavigator {
  display: flex;
  margin-left: 20px;

  gap: 5px;
  width: 100%;
  border-bottom: 1px solid black;
}
.deviceDetailsNavigator.trends {
  margin-top: 20px;
}
.pointProperty::before {
  content: "";
  position: absolute;
  left: -10px;
  bottom: 0;
  width: calc(100% + 10px);
}
.deviceDetailsNavigatorOption {
  padding: 10px;
  background-color: var(--item-selected-color);
  border-radius: 8px;
  cursor: pointer;
  border-radius: 0px;
  width: 150px;
  border-top: 1px solid var(--font-color);
  border-left: 1px solid var(--font-color);
  border-right: 1px solid var(--font-color);
  border-top-left-radius: var(--button-border-radius);
  border-top-right-radius: var(--button-border-radius);
  padding-top: 12px;
}
.deviceDetailsNavigatorOption.selected {
  background-color: var(--background-color-violet);
  color: var(--light-font-color);
  border-radius: 0px;
  border-top-left-radius: var(--button-border-radius);
  border-top-right-radius: var(--button-border-radius);
  padding-top: 12px;
  font-weight: 600;
}
.pointsDetailsPropertiesWrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: auto;
  width: 90%;
}
.pointsDetailsPropertiesLeft,
.pointsDetailsPropertiesRight {
  display: flex;
  flex-direction: column;
  width: 100%;
  margin: auto;
  justify-content: center;
}
.pointsDetailsPropertiesGroup {
  width: 100%;
}

.deviceDetailsRow {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
}
.liveDataWidgetContainer {
  flex: 1; /* Flex to control the space for the widget */
  margin-right: 20px; /* Spacing between widget and tabs */
}
.tabsContainer {
  flex: 2; /* Tabs take more space compared to the widget */
  display: flex;
  flex-direction: column;
}
.deviceDetailsRowContent {
  display: flex;
  flex-direction: column;
  margin-top: 20px; /* Space between the rows */
  /* Additional styling for content */
}
.pointProperty {
  width: 100%;
  padding-bottom: 10px; /* Space between the border and the content */
}
.deviceDetailsHeaderContainer {
  width: 100%;
  align-items: center;
  margin: auto;
}
.pointHeaderGroup {
  display: flex;
  height: -moz-fit-content;
  height: fit-content;
  border-radius: var(--heading-border-radius);
  align-items: center;
  padding-top: 4px;
  padding-bottom: 4px;
  padding-left: 15px;
  cursor: pointer;
  color: var(--white-color);
  text-align: left;
  font-size: 14px;
  font-weight: 500;
  line-height: 30px;
  gap: 10px;
  white-space: nowrap;
  min-height: 29px;
  width: 110px;
  background-color: var(--background-color-violet);
}
.deviceDetailsPointsStyle {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  gap: 20px;
}
