.buttonWidget {
  display: flex;
  margin: 0;
  height: 100%;
  width: 100%;
  justify-content: center;
  align-items: center;
  border-radius: 10px;
  cursor: pointer;
  text-decoration: none;
}
.buttonWidget span {
  font-weight: var(--heading-font-weight);
}
