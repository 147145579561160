.backupRestoreSettings {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  height: 100%;
  overflow: scroll;
  text-align: center;
}
.backupAndRestoreHeader {
  font-size: var(--heading-size);
  margin-bottom: 40px;
  color: var(--header-color);
  width: 100%;
  text-align: center;
}

.backupRestoreSettingsContents {
  display: flex;
  flex-direction: column;
  height: 100%;
  background-color: var(--background-color);
  border-radius: var(--container-border-radius);
  margin-top: 10px;
}
.backupRestoreSettings.dark {
  background-color: var(--dark-secondary-color);
}
.backupRestoreSettingsContent {
  display: flex;
  margin-top: 80px;
  margin-left: auto;
  margin-right: auto;
  display: flex;
  flex-direction: row;
  gap: 20px;
}

.backupRestoreSettingsData {
  border-radius: var(--container-border-radius);
  background-color: var(--container-background-color);
  box-shadow: rgba(0, 0, 0, 0.05) 0px 6px 24px 0px,
    rgba(0, 0, 0, 0.08) 0px 0px 0px 1px;
  padding: 25px;
  backdrop-filter: var(--container-backdrop-filter);
}
.backupRestoreLastBackupTime {
  display: flex;
  gap: 80px;
}
.backupRestoreLastBackupStatus {
  display: flex;
  gap: 20px;
}
.backupRestoreStatusLabel {
  margin-right: 50px;
}
.backupRestoreStatusDot {
  color: var(--light-green-color);
  margin: auto;
}
.backupRestoreResetBtn {
  display: flex;
  padding: 10px;
  border-radius: var(--container-border-radius);
  color: var(--white-color);
  font-weight: 500;
  font-size: 16px;
  cursor: pointer;
  justify-content: center;
  background-color: var(--light-red-color);
}
