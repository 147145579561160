.displayConfigurationForm {
  position: absolute;
  min-width: 20%;
  width: 20%;
  height: 98%;
  top: 1%;
  right: 0;
  display: flex;
  background-color: var(--dialog-bg);
  z-index: 3000;
  flex-direction: column;
}
.displayTemplateConfigurationForm {
  position: absolute;
  min-width: 20%;
  width: 40%;
  height: 98%;
  top: 1%;
  right: 0;
  display: flex;
  background-color: var(--dialog-bg);
  z-index: 3000;
  flex-direction: column;
}
.displayDataConfigurationFormContentHeading {
  font-size: 24px;
  text-align: center;
  margin-top: 30px;
}
.displayDataConfigurationFormButtons {
  display: flex;
  margin-top: auto;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 25px;
  gap: 10px;
}
.displayDataConfigurationFormImageContainer {
  width: 100%;
  display: flex;
  align-items: center;
  margin-left: auto;
  margin-right: auto;
  justify-content: center;
  height: 100%;
  object-fit: contain;
}
.imageWidget {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.imageDragUploadText {
  font-weight: 600;
}
.imageUploadBtn {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.imageUploadImage {
  font-size: 80px;
  width: 100%;
  cursor: pointer;
  margin: auto;
  display: flex;
  justify-content: center;
  color: var(--background-color-violet);
}
.imageWidgetImageDeleteBtn {
  position: absolute;
  top: 30%;
  color: var(--light-red-color);
  right: 0;
  font-size: 18px;
  cursor: pointer;
}
.displayDataConfigurationFormImage {
  width: 100%;
}

.displayDataConfigurationFormContents {
  gap: 10px;
  height: 75vh;
  overflow: scroll;
  margin-top: 30px;
}

.displayDataConfigurationLimit {
  display: flex;
  height: 100%;
  align-items: center;
  justify-content: center;
}

.selectPointsLabel {
  text-align: left;
  margin-left: 20px;
  font-weight: 600;
}
.displayDataConfigurationFormContentsColorInput {
  outline: none;
  margin-left: auto;
  margin-right: auto;
}
.displayDataConfigurationFormContentsInput {
  border: none;
  width: 80%;
  margin-left: auto;
  margin-right: auto;
  border-radius: 10px;
  outline: none;
  padding: 15px;
  background-color: var(--input-background-color);
}
.colorPicker {
  width: 90%;
}

.displayDataConfigurationFormContentsInputGroup {
  display: flex;
  gap: 10px;
}
.filterFormContentsSaveBtn {
  background-color: var(--heading-details-color);
  color: var(--light-font-color);
  width: 80px;
  text-align: center;
  padding-left: 10px;
  padding-right: 10px;
  cursor: pointer;
  padding-top: 5px;
  padding-bottom: 5px;
  border-radius: var(--button-border-radius);
}
.filterFormContentsCancelBtn {
  background-color: var(--white);
  color: var(--font-color);
  width: 80px;
  text-align: center;
  padding-left: 10px;
  padding-right: 10px;
  padding-top: 5px;
  padding-bottom: 5px;
  border: 1px solid var(--font-color);
  cursor: pointer;
  border-radius: var(--button-border-radius);
}

.colorSelectorContainer {
  display: flex;
  flex-direction: column;
  gap: 10px;
  margin-top: 20px;
}
.colorSelector {
  width: 100%;
  margin: auto;
  height: 100px !important;
  overflow: scroll;
  cursor: pointer;
  border: none;
}
.colorSelector div div {
  box-shadow: none !important;
}
.colorSelector .swatches-picker {
  background-color: var(
    --picker-background-color,
    #333
  ) !important; /* fallback for dark */
}
.recentColors {
  display: flex;
  flex-wrap: wrap;
  margin: auto;
  gap: 5px;
  width: 90%;
}

.recentColorBox {
  width: 30px;
  height: 30px;
  cursor: pointer;
  border: 1px solid #ccc;
  border-radius: 5px;
}

@media screen and (max-width: 768px) {
  .displayConfigurationForm {
    width: 60%;
  }
}
.displayDataConfigurationFormContentSubHeading {
  font-size: 15px;
  text-align: left;
  margin-left: 10px;
  margin-bottom: 20px;
  font-weight: var(--heading-font-weight);
}
