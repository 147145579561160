.assets {
  display: flex;
  width: 100%;
  height: 100%;
  gap: var(--gap);
}
.assetsContent {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  gap: var(--gap);
}
.assetsBrowserheader {
  display: flex;
  width: 100%;
  margin-left: auto;
  margin-right: auto;
  height: 15%;
  flex-direction: column;
  justify-content: space-evenly;
}
.assetsBrowserheaderTitle {
  display: flex;
  width: 90%;
  height: 30%;
  align-items: center;
  margin-left: auto;
  margin-right: auto;
  justify-content: space-between;
}
.assetsBrowserHeaderBtns {
  display: flex;
  align-items: center;
  gap: 5px;
}
.assetsBrowserheaderTitle span {
  font-size: 16px;
  font-weight: var(--heading-font-weight);
}
.assetsBrowserContent {
  display: flex;
  width: 90%;
  height: 73vh;
  overflow: scroll;
  margin: auto;
}
.treeItem {
  display: flex;
  width: 100%;
  padding-top: 5px;
  padding-bottom: 5px;
  gap: 5px;
  text-align: left;
  align-items: flex-start;
}

.treeItem.selected {
  display: flex;
  width: 100%;
  text-align: left;
  width: fit-content;
  background-color: var(--container-background-color);
  backdrop-filter: var(--container-backdrop-filter);
  border: var(--container-border);
  overflow: hidden;
}
.expandCollapseIcon {
  display: flex;
  align-items: center;
}
.folderNode {
  display: flex;
  width: 100%;
  align-items: center;
  gap: 15px;
  font-size: var(--heading-font-size);
  font-weight: var(--heading-font-weight);
}
.leafNode {
  display: flex;
  width: 85%;
  margin-left: auto;
  margin-right: auto;
}
.leafNode.selected span {
  display: flex;
  width: 100%;
  text-align: left;
  align-items: flex-start;
}
.assetCanvas {
  display: flex;
  width: 100%;
  height: 100%;
}
.assetCanvasPointsContainer {
  display: flex;
  flex-direction: column;
  width: 49%;
  height: 95%;
  margin: auto;
  background-color: var(--container-background-color);
  backdrop-filter: var(--container-backdrop-filter);
  border: var(--container-border);
  border-radius: var(--container-border-radius);
}
.assetCanvasFolderView {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 95%;
  margin: auto;

  border-radius: var(--container-border-radius);
}
.pointsContainerHeader {
  display: flex;
  flex-direction: column;
  width: 95%;
  margin-left: auto;
  margin-right: auto;
  margin-top: 10px;
}
.pointsContainerTitle {
  text-align: center;
  width: 100%;
  align-items: center;
  justify-content: center;
  display: flex;
  font-size: var(--custom-header-font-size);
  font-weight: var(--heading-font-weight);
}
.assetCanvasContent {
  display: flex;
  flex-direction: column;
  width: 80%;
  height: 100%;
  margin-left: auto;
  margin-right: auto;
  flex: 1;
  position: relative;
  min-height: 200px;
}

/* .assetCanvasContent > div {
  display: flex;
  align-items: center;
  margin-top: 10px;
} */

.assetCanvasContent button {
  background-color: #007bff;
  color: white;
  border: none;
  padding: 5px 10px;
  cursor: pointer;
}

.assetCanvasContent button:hover {
  background-color: #0056b3;
}
.addFolderIcon {
  width: fit-content;
  cursor: pointer;
}
.newFolderContainer {
  display: flex;
  width: 100%;
  height: 100%;
}
.newFolderInputContainer {
  display: flex;
  flex-direction: column;
  background-color: var(--dialog-color);
  border-radius: var(--container-border-radius);
  border: var(--container-border);
  padding: 10px;
  width: 20%;
  justify-content: space-between;
  height: 25%;
  margin: auto;
  align-items: center;
}
.newFolderContainerBtns {
  display: flex;
  gap: 5px;
}
.nodeContent {
  display: flex;
  gap: 5px;
  align-items: center;
}
.nodeBtns {
  display: flex;
  gap: 3px;
  align-items: center;
}
.folderEditMode {
  font-size: 20px;
  font-weight: 400;
}
.assetCanvasBtn {
  position: absolute;
  width: fit-content;
  display: flex;
  margin-left: auto;
  margin-right: auto;
  bottom: 0;
  left: 30%;
  right: 30%;
}
.assetsBrowserTree {
  height: 100% !important;
  width: 100% !important;
}
/* .assetsBrowserTree {
  height: 87% !important;
  overflow: scroll;
} */
/* .assetsBrowserTree::-webkit-scrollbar {
  width: 0px;
}
.assetsBrowserTree::-webkit-scrollbar:horizontal {
  display: flex !important;
  height: 7px;
}
.assetsBrowserTree::-webkit-scrollbar-track {
  background: var(--container-background-color);
  border-radius: var(--container-border-radius);
  border: var(--container-border);
}
.assetsBrowserTree::-webkit-scrollbar-thumb {
  border-radius: 20px;
  background: #555;
}
.assetsBrowserTree::-webkit-scrollbar-thumb:hover {
  background: var(--font-color);
} */
.noAssetFound {
  height: 100%;
  width: 100%;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  gap: 15px;
  align-items: center;
  justify-content: center;
}
.noAssetFound span {
  font-size: 20px;
  font-weight: var(--heading-font-weight);
}
.assetPointProperties {
  display: flex;
  height: 100%;
  width: 100%;
  background-color: var(--container-background-color);
  backdrop-filter: var(--container-backdrop-filter);
  border: var(--container-border);
  border-radius: var(--container-border-radius);
}
.assetPointPropertiesRight {
  display: flex;
  height: 100%;
  justify-content: center;
  margin-top: 20px;
}
.assetSelected {
  padding: 3px;
}
