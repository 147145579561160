.configurationSettings {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  text-align: center;
  gap: 20px;
  height: 100%;
}
.configurationSettingsContent {
  height: 100%;
}

.configurationHeader {
  font-size: var(--heading-size);
  margin-bottom: 40px;
  color: var(--header-color);
  width: 100%;
  text-align: center;
}
.configurationSettingsSMSContent {
  display: flex;
  flex-direction: column;
  margin-top: 15px;
  width: 50%;
  margin-left: auto;
  margin-right: auto;
  overflow: scroll;
  max-height: 60vh;
}

.secretKeyInputGroup {
  display: flex;
  height: fit-content;
}
