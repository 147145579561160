.guageChart {
  width: 100%;
  height: 100%;
  border-radius: 20px;
  object-fit: contain;
  background-color: white;
}
.guageChart.dark {
  background-color: var(--dark-background-color);
}
.guageChartLabel {
  font-weight: 1000;
  font-size: 16px;
  color: black;
  text-align: left;
  margin-left: 10px;
}
.guage {
  width: 100%;
  display: flex;
  flex-direction: column;
}
.guageChartChart {
  display: flex;
  margin: auto;
  justify-content: center;
}
