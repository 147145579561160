.ruleList {
  width: 100%;
  height: 35vh;
}
.ruleListHeader {
  display: flex;
  height: 36px;
  margin: auto;
  width: 90%;
  justify-content: space-between;
  text-align: center;
  align-items: center;
}

.ruleListHeader span {
  font-size: 15px;
  font-weight: var(--custom-header-font-weight);
}
.ruleListContent {
  overflow: scroll;
  height: 58%;
}
.rule {
  display: flex;
  width: 80%;
  margin: auto;
  margin-top: 10px;
  justify-content: space-between;
  gap: 20px;
}
.rule span {
  width: 90%;
  font-size: var(--heading-font-size);
}
.ruleListDeleteBtn {
  width: 10%;
  margin-top: auto;
  color: var(--light-red-color);
  cursor: pointer;
  margin-bottom: auto;
}
.rule li {
  width: 90%;
  list-style: none;
  text-align: left;
  cursor: pointer;
}
.createNewRuleBtn {
  display: flex;
  width: fit-content;
  margin: auto;
  margin-top: 5px;
  gap: 10px;
  background-color: var(--light-heading-color);
  padding: 10px;
  border-radius: 10px;
  cursor: pointer;
}
.selectedRule {
  background-color: var(--light-heading-color);
  border-radius: 10px;
  padding: 5px;
}
