.display {
  display: flex;
  position: relative;
  width: 100%;
  gap: var(--gap);
  height: 100%;
}
.canvasContainer {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  border-radius: 8px;
}
.canvasWidgetsContainer {
  display: flex;
  flex-direction: row;
  width: 100%;
  height: 100%;
  gap: 10px;
}

.cancel-btn {
  background-color: var(--light-red-color);
  padding-left: 15px;
  padding-right: 15px;
  padding-top: 5px;
  padding-bottom: 5px;
  font-size: 14px;
  cursor: pointer;
  color: var(--light-font-color);
  border-radius: 8px;
  font-weight: 600;
  width: fit-content;
  margin: auto;
  margin-top: 30px;
}

@media screen and (max-width: 768px) {
  .display {
    padding: 0px;
    margin: 0px;
    gap: 5px;
    height: 90%;
  }
  .canvasContainer {
    width: 100%;
    height: 90%;
  }
}
.addDeviceToTemplate {
  display: flex;
  width: 100%;
  height: 100%;
}
.addDeviceToTemplateContent {
  display: flex;
  flex-direction: column;
  width: fit-content;
  height: fit-content;
  border-radius: var(--container-border-radius);
  justify-content: center;
  margin: auto;
  align-items: center;
  gap: 10px;
}
.addDeviceToTemplateHeading {
  display: flex;
  font-weight: 600;
  font-size: 18px;
}
.addDeviceToTemplateForm {
  display: flex;
  flex-direction: column;
  width: fit-content;
  height: 100%;
  border-radius: var(--container-border-radius);
  justify-content: center;
  margin: auto;
  align-items: center;
  gap: 10px;
  padding: 10px;
}
.addDeviceToTemplateSearchBox {
  display: flex;
  border-radius: var(--button-border-radius);
  align-items: center;
  font-weight: 600;
  padding: 10px;
  background-color: var(--input-background-color);
  width: 100%;
}
.addDeviceToTemplateSearchBox input {
  outline: none;
  border: none;
  background-color: transparent;
  width: 90%;
}
.addDeviceToTemplateSearchBoxSearchIcon {
  margin: auto;
  width: 10%;
}
.addDeviceToTemplateFormInputGroup {
  display: flex;
  width: 100%;
  gap: 10px;
  margin: auto;
  justify-content: center;
}
.addDeviceToTemplateFormInputGroup input {
  margin-top: auto;
  margin-bottom: auto;
}
.addDeviceToTemplateFormList {
  height: 200px;
  width: 100%;
  overflow: scroll;
  flex-direction: column;
  border-radius: var(--container-border-radius);
  padding: 10px;
  background-color: var(--input-background-color);
}
.addDeviceToTemplateFormDeviceInputGroup {
  display: flex;
  width: 100%;
  gap: 10px;
  margin: auto;
  justify-content: flex-start;
}
.addDeviceToTemplateBtns {
  display: flex;
  gap: 10px;
}

.deleteDisplay {
  color: red;
  margin-right: 15px;
  font-size: 16px;
  position: relative;
  bottom: 10px;
}
.canvasHeaderLeft {
  display: flex;
}
.canvasHeaderRight {
  display: flex;
}
