.backupRestore {
  display: flex;
  width: 100%;
  margin-bottom: 10px;
}
.backupRestoreConfig {
  display: flex;
  width: 50%;
  gap: 50px;
  margin: auto;
}
.backupRestoreConfigBtns {
  display: flex;
  width: 50%;
  margin: auto;
  justify-content: center;
  gap: 10px;
}
.backupRestoreConfigBtn {
  background-color: var(--background-color-violet);
  padding-left: 10px;
  padding-right: 10px;
  color: white;
  padding-top: 5px;
  border-radius: var(--button-border-radius);
  cursor: pointer;
  padding-bottom: 5px;
}
