.trendNavigator {
  min-width: 16%;
  height: 100%;
}

.objectList {
  text-align: left;
  cursor: pointer;
  margin-left: 20px;
}
.trendNavigatorContent {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 83%;
  align-items: center;
  margin: auto;
  overflow: scroll;
  margin-top: 10px;
}

.trendNavigatorContent p {
  margin: 0;
  width: 90%;
  text-align: left;
}
.trendNavigatorContent li {
  text-align: center;
  display: flex;
  width: 90%;
  justify-content: space-between;
  padding: 5px;
  list-style: none;
}
.trendName {
  width: 80%;
  text-align: left;
}
.trend-item {
  cursor: pointer;
  padding: 10px;
}

.trend-item.selected {
  background-color: var(--settings-selected-color);
  color: var(--font-color);
}
