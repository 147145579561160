.deviceNetworkForm {
  position: absolute;
  min-width: 20%;
  height: 95%;
  right: 0;
  padding: 10px;
  display: flex;
  z-index: 100;
  margin: auto;
  background-color: var(--dialog-color);
  flex-direction: column;
}
.deviceNetworkFormHeading {
  font-size: 24px;
  text-align: center;
  margin-top: 30px;
}
.deviceNetworkFormContents {
  display: flex;
  gap: 10px;
  height: 80vh;
  overflow: scroll;
  flex-direction: column;
  margin-top: 20px;
}
/* .deviceNetworkFormContents label {
    text-align: left;
    margin-left: 20px;
    font-weight: 600;
  } */
/* .deviceNetworkFormContents input {
  border: none;
  width: 80%;
  margin: auto;
  border-radius: 10px;
  outline: none;
  padding: 15px;
} */
/* .deviceNetworkFormContents select {
  border: none;
  width: 90%;
  margin: auto;
  border-radius: 10px;
  outline: none;
  padding: 15px;
} */

.deviceNetworkFormContentsBtns {
  margin-left: auto;
  margin-right: auto;
  display: flex;
  gap: 10px;
  margin-top: 10px;
}
.deviceNetworkFormContentsSaveBtn {
  background-color: var(--heading-details-color);
  color: white;
  width: 80px;
  text-align: center;
  padding-left: 10px;
  padding-right: 10px;
  cursor: pointer;
  padding-top: 5px;
  padding-bottom: 5px;
  border-radius: var(--button-border-radius);
}
.deviceNetworkFormContentsCancelBtn {
  background-color: var(--white-color);
  color: var(--font-color);
  width: 80px;
  text-align: center;
  padding-left: 10px;
  padding-right: 10px;
  padding-top: 5px;
  padding-bottom: 5px;
  cursor: pointer;
  border: 1px solid var(--light-heading-color);
  border-radius: var(--button-border-radius);
}

@media screen and (max-width: 768px) {
  .deviceNetworkForm {
    width: 70%;
    height: 95%;
    margin: auto;
  }
}
