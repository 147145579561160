.operatorNodeForm {
  position: absolute;
  top: 0;
  min-width: 21%;
  height: 95%;
  right: 0;
  top: 10px;
  border-radius: 20px;
  background-color: var(--dialog-bg);
  display: flex;
  z-index: 100;
  flex-direction: column;
}

.operatorNodeFormHeading {
  font-size: 24px;
  text-align: center;
  margin-top: 30px;
}
.operatorNodeFormContents {
  display: flex;
  gap: 10px;
  width: 90%;
  overflow: scroll;
  flex-direction: column;
  margin-top: 30px;
  margin-left: auto;
  margin-right: auto;
}

.formInputFieldGroup {
  display: flex;
  flex-direction: column;
  gap: 5px;
}
.formInputFieldGroup input {
  width: 95%;
  outline: none;
  padding-top: 10px;
  padding-bottom: 10px;
  padding-left: 10px;
  background-color: var(--container-background-color);
  border: var(--container-border);
  border-radius: var(--input-border-radius);
  color: var(--font-color);
}
.formInputFieldGroup select {
  width: 100%;
  outline: none;
  padding-top: 10px;
  padding-bottom: 10px;
  padding-left: 10px;
  background-color: var(--container-background-color);
  border: var(--container-border);
  border-radius: var(--input-border-radius);
  color: var(--font-color);
}

.operatorNodeFormContentsBtns {
  display: flex;
  gap: 10px;
  margin-top: auto;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 10px;
}
.operatorNodeFormContentsSaveBtn {
  background-color: var(--light-green-color);
  color: white;
  width: 80px;
  text-align: center;
  padding-left: 10px;
  padding-right: 10px;
  cursor: pointer;
  padding-top: 5px;
  padding-bottom: 5px;
  border-radius: 10px;
}
.operatorNodeFormContentsCancelBtn {
  background-color: var(--light-red-color);
  color: white;
  width: 80px;
  text-align: center;
  padding-left: 10px;
  padding-right: 10px;
  padding-top: 5px;
  padding-bottom: 5px;
  cursor: pointer;
  border-radius: 10px;
}
.delayInputGroup {
  display: flex;
  width: 80%;
  margin-left: auto;
  margin-right: auto;
}
.delayInputGroup label {
  display: flex;
  width: 100%;
  margin-left: 20px;
}
.delayInputGroup input {
  /* width: 20px;
  height: 5px; */
  text-align: center;
  background-color: var(--container-background-color);
  border: var(--container-border);
  border-radius: var(--input-border-radius);
  color: var(--font-color);
}
.delayInputGroup span {
  margin: auto;
  font-size: 14px;
  font-weight: 600;
}
