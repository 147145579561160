.licenseSettings {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  height: 100%;
  gap: 20px;
  overflow: scroll;
  text-align: center;
}

.licenseSettingContent {
  display: flex;
  width: 100%;
  height: 100%;
}
.licenseSettingsForm {
  margin: auto;
}
.licenseSettingsStatusDot {
  color: var(--light-green-color);
  margin-right: 10px;
}
.licenseSettingsGroup {
  display: flex;
  gap: 40px;
  justify-content: space-between;
  align-items: flex-start;
}

.label {
  text-align: left;
  flex-basis: 50%;
  font-weight: 600;
}

.data {
  height: fit-content;
  width: 50%;
  display: flex;
  text-align: left;
  margin: auto;
  flex-basis: 60%;
}
.licenseSettingsDetails {
  background-color: var(--container-background-color);
  border-radius: 10px;
  color: var(--font-color);
  padding: 10px;
  max-height: 300px;
}
