.userSettings {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  text-align: center;
  height: 100%;
  gap: 10px;
}
.userSettingsHeading {
  display: flex;
  width: 100%;

  justify-content: flex-end;
}
.userSettingsContent {
  width: 100%;
  height: 100%;
}
.usersTableHeader {
  text-align: left !important;
}
/* .usersTableData {
  text-align: center !important;
} */
.usersTableBtns {
  justify-content: center !important;
}

.userSettingsCreateBtn {
  display: flex;
  gap: 5px;
  width: fit-content;
  padding-left: 15px;
  padding-right: 15px;
  padding-top: 5px;
  padding-bottom: 5px;
  cursor: pointer;
  border-radius: 10px;
  background-color: var(--heading-details-color);
  color: var(--light-font-color);
}
.userSettingsCreateBtn.dark {
  color: black;
}
.userSettingsCreateUserPopup {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  backdrop-filter: blur(5px);
  z-index: 999;
  display: flex;
  justify-content: center;
  align-items: center;
}
.userSettingsCreateUser {
  width: 40%;
  padding: 20px;
  background-color: var(--dialog-bg);
  backdrop-filter: blur(5px);
  border-radius: 10px;
}
.userSettingsCreateUserFormInputs {
  display: flex;
  gap: 30px;
  flex-direction: row;
}
.userSettingsCreateUserFormLeft {
  width: 50%;
}
.userSettingsCreateUserFormRight {
  width: 50%;
}
.userSettingsCreateUserFormBtns {
  display: flex;
  justify-content: center;
  margin-top: 20px;
  gap: 10px;
}
.userSettingsCreateUserFormBtn {
  background-color: var(--heading-details-color);
  color: var(--light-font-color);
  padding: 10px;
  font-weight: 600;
  cursor: pointer;
  border-radius: var(--button-border-radius);
}
.userSettingsCancelUserFormBtn {
  background-color: var(--white-color);
  padding: 10px;
  cursor: pointer;
  color: var(--font-color);
  border: 1px solid var(--font-color);
  font-weight: 600;
  border-radius: var(--button-border-radius);
}

.userContainer {
  display: flex;
  justify-content: "space-between";
  align-items: "center";
}

.user-settings-create-btn {
  cursor: pointer;
  background-color: var(--heading-details-color);
  padding-top: 8px;
  padding-bottom: 8px;
  padding-left: 15px;
  padding-right: 10px;
  border-radius: var(--button-border-radius);
  color: var(--light-font-color);
  position: relative;
  bottom: 10px;
}

.createUserBtn {
  position: relative;
  font-size: 12px;
}
