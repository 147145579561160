.tableWidget {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 96%;
  padding: 5px;
  border-radius: 10px;
  box-shadow: none;
  overflow: hidden;
  margin: auto;
}

.tableWidgetContent {
  overflow: scroll;
  margin-top: 20px;
  width: 100%;
  margin: auto;
  height: 100%;
}

.tableWidgetLabel {
  margin: 0;
  text-align: center;
}

.dataTable {
  border-collapse: separate;
  border-spacing: 0;
  margin: auto;
  width: 98%;
  height: 98%;
  border-radius: 10px;
  overflow: hidden;
}

.dataTable th,
.dataTable td {
  border: 1px solid #ddd;
  padding: 8px;
  text-align: left;
}

.dataTable th:first-child {
  border-top-left-radius: 10px;
}

.dataTable th:last-child {
  border-top-right-radius: 10px;
}

.dataTable tr:last-child td:first-child {
  border-bottom-left-radius: 10px;
}

.dataTable tr:last-child td:last-child {
  border-bottom-right-radius: 10px;
}

.dataTable th {
  background-color: var(--input-background-color);
  color: var(--font-color);
}

.tableWidgetLabel {
  font-weight: 1000;
  font-size: 16px;
  color: var(--font-color);
  text-align: left;
  margin-left: 10px;
}

.writableInput {
  display: flex;
  border-radius: var(--input-border-radius);
  background-color: var(--container-background-color);
  border: var(--container-border);
  padding: 10px;
  font-size: 14px;
  color: var(--font-color);
  font-weight: 500;
  margin: auto;
  width: 90%;
  outline: none;
}
