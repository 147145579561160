.imageWidgetLabel {
  display: flex;
  justify-content: center;
}
.imageWidgetImage {
  display: flex;
  width: 100%;
  height: 100%;
  overflow: scroll;
}
