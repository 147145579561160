.trendCanvas {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  text-align: center;
  gap: 10px;
  overflow: scroll;
  height: 100%;
}

.trendCanvasLabel {
  display: flex;
  justify-content: space-between;
}
.trendCanvasLabelText {
  display: flex;
  gap: 10px;
}
.trendCanvasLabelText span {
  margin: auto;
  font-weight: var(--heading-font-weight);
  font-size: var(--heading-font-size);
}
.trendCanvasLabelDeleteBtn {
  margin-top: auto;
  margin-bottom: auto;
  color: var(--light-red-color);
  font-size: 20px;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
  font-weight: 700;
  background-color: var(--item-selected-color);
  padding: 5px;
  cursor: pointer;
  border-radius: 50px;
}
.trendCanvasLabelRefreshBtn {
  margin-top: auto;
  margin-bottom: auto;
  font-size: 20px;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
  font-weight: 700;
  background-color: var(--item-selected-color);
  padding: 5px;
  cursor: pointer;
  border-radius: 50px;
}
.trendCanvasLabelExportBtn {
  margin-top: auto;
  margin-bottom: auto;
  background-color: var(--heading-details-color);
  color: var(--light-font-color);
  padding-left: 15px;
  padding-right: 15px;
  padding-top: 5px;
  padding-bottom: 5px;
  font-size: 14px;
  cursor: pointer;
  border-radius: var(--button-border-radius);
}
