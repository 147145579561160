/* customToastStyles.css */

.Toastify__toast--default {
  background: var(--container-background-color) !important;
  color: var(--font-color) !important;
  backdrop-filter: blur(5px) !important;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1) !important;
  border-radius: 10px !important;
}

.Toastify__toast--info {
  background: var(--container-background-color) !important;
  color: var(--font-color) !important;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1) !important;
  border-radius: 10px !important;
  backdrop-filter: blur(5px) !important;
}

.Toastify__toast--success {
  background: var(--container-background-color) !important;
  color: var(--font-color) !important;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1) !important;
  border-radius: 10px !important;
  backdrop-filter: blur(5px) !important;
}

.Toastify__toast--warning {
  background: var(--container-background-color) !important;
  color: var(--font-color) !important;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1) !important;
  border-radius: 10px !important;
  backdrop-filter: blur(5px) !important;
}

.Toastify__toast--error {
  background: var(--container-background-color) !important;
  color: var(--font-color) !important;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1) !important;
  border-radius: 10px !important;
  backdrop-filter: blur(5px) !important;
}

.Toastify__progress-bar {
  background: var(--light-heading-color) !important;
}

.Toastify__close-button {
  color: var(--light-red-color) !important;
}

.Toastify__close-button:hover,
.Toastify__close-button:focus {
  color: rgb(245, 3, 3) !important;
}
