.energyTemplateContainer {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 80vh;
  overflow: scroll;
}
.energyTemplateTop {
  display: flex;
  flex-direction: column;
  gap: 10px;
  height: 100%;
  margin: auto;
  overflow: scroll;
  width: 100%;
}
.energyTemplateBottom {
  display: flex;
  height: 80%;
}
.energyTemplateMainContainer {
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 10px;
  height: 100%;
}
.templateHomeBtn {
  display: flex;
  width: fit-content;
  padding: 10px;
  margin-left: 30px;
  margin-bottom: 10px;
  border-radius: var(--input-border-radius);
  cursor: pointer;
  background-color: var(--primary-color);
  justify-content: center;
  color: var(--white-color);
}
.templateHomeBtn:hover {
  background-color: rgb(0, 90, 192);
  color: var(--white-color);
}
.energyTemplateLeft {
  display: flex;
  flex-wrap: wrap;
  gap: 5px;
  width: 80%;
}
.energyTemplateRight {
  display: flex;
  flex-direction: column;
  gap: 5px;
  width: 20%;
}
.largeCard {
  width: 100%;
  height: 60%;
  border-radius: var(--container-border-radius);
  background-color: var(--container-background-color);
  backdrop-filter: var(--container-backdrop-filter);
}
.smallCard {
  width: 100%;
  height: 100%;
}
.displayTemplateRemoveWidget {
  position: absolute;
  right: 0;
  cursor: pointer;
  z-index: 999;
}
.cardHeading {
  display: flex;
  width: 90%;
  margin: auto;
  align-items: center;
  height: 3.5vh;
  justify-content: space-between;
}
.cardHeading span {
  font-size: var(--custom-header-font-size);
  font-weight: var(--heading-font-weight);
}
.cardBtnContainer {
  display: flex;
  gap: 5px;
  margin-right: 30px;
}
.cardBtn {
  padding: 5px;
  cursor: pointer;
  border: 1px solid var(--primary-color);
  border-radius: 5px;
}
.cardBtn.selected {
  padding: 5px;
  cursor: pointer;
  background-color: var(--primary-color);
  color: var(--white-color);
  border-radius: 5px;
}
.cardBtn:hover {
  background-color: rgb(0, 90, 192);
  color: var(--white-color);
}
.lineTemplateChart {
  display: flex;
  width: 75%;
  height: 90%;
  margin-top: 10px;
  margin-left: auto;
  margin-right: auto;
}
.cardContent {
  width: 100%;
  margin-top: 20px;
  height: 80%;
}
.cardHeading.small {
  height: 3vh;
  width: 85%;
  text-align: center;
}
.cardSubHeading.small {
  width: 85%;
}
.smallCardContent {
  display: flex;
  height: 80%;
  width: 85%;
  margin-left: auto;
  margin-right: auto;
  justify-content: left;
  align-items: center;
  overflow: scroll;
}
/* @import url("https://fonts.cdnfonts.com/css/library-3-am"); */
.smallCardContent span {
  /* font-family: "LIBRARY 3 AM", sans-serif; */
  font-size: 40px;
  text-align: left;
  font-weight: 700;
  color: var(--font-color);
  margin-top: auto;
  margin-bottom: auto;
}
.mediumCard {
  height: 100%;
  width: 100%;
}
.cardSubHeading {
  display: flex;
  width: 90%;
  margin: auto;
}
.templateHeading {
  width: 100%;
  height: 7%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.templateLabel {
  font-size: var(--custom-header-font-size);
  font-weight: var(--heading-font-weight);
  text-align: center;
}
.energyTemplateCardGroup {
  width: 100%;
  height: 100%;
  display: flex;
  gap: 5px;
}
.vrfFilterCardGroup {
  width: 95%;
  height: 100%;
  display: flex;
  gap: 30px;
  margin: auto;
  margin-top: 10px;
  justify-content: space-evenly;
}

.consumptionCard {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  overflow: scroll;
  border-radius: var(--container-border-radius);
  backdrop-filter: var(--container-backdrop-filter);
  justify-content: flex-start;
  width: 30%;
  height: 100%;
}
.consumptionCardContent {
  display: flex;
  width: 90%;
  height: 90%;
  margin: auto;
  align-items: center;
  justify-content: space-between;
}
.consumptionName {
  width: fit-content;
  font-size: var(--custom-header-font-size);
}
.consumptionValue {
  /* font-family: "LIBRARY 3 AM", sans-serif; */
  font-size: 30px;
}
