.configurationSettingsTelegram {
  display: flex;
  flex-direction: column;
  height: 90%;
  width: 90%;
  margin: auto;
  margin-bottom: auto;
  margin-top: auto;
  overflow: scroll;
}
.telegamHeader {
  margin-top: 20px;
  text-align: start;
  font-size: 17px;
  font-weight: 600;
}
.telegramContent {
  display: flex;
  gap: 10px;
  height: 80%;
}
.telegramContentLeft {
  display: flex;
  flex-direction: column;
  gap: 10px;
  width: 50%;
  margin: auto;
}
.telegramContentRight {
  display: flex;
  flex-direction: column;
  gap: 10px;
  width: 50%;
  margin: auto;
}
.infoPanel {
  display: flex;
  flex-direction: column;
  gap: 10px;
}
.infoPanel span {
  text-align: start;
}
.inputPanel {
  display: flex;
  flex-direction: column;
  gap: 20px;
  margin-top: 20px;
}
.telegramSaveBtn {
  display: flex;
  width: 20%;
  border-radius: 10px;
  text-align: center;
  margin-left: auto;
  justify-content: center;
  margin-right: auto;
}
.telegramSaveContentBtn {
  display: flex;
  width: 40%;
  background-color: var(--light-button-color);
  border-radius: 10px;
  padding: 10px;
  margin: auto;
  justify-content: center;
  margin-top: 20px;
  cursor: pointer;
}
.bacsysQrImageWithLabel {
  display: flex;
  flex-direction: column;
  width: 50%;
  height: 50%;
  /* margin-bottom: auto; */
  margin-left: auto;
  margin-right: auto;
  object-fit: contain;
  justify-content: space-evenly;
}
.bacsysImageQr {
  height: 80%;
  object-fit: cover;
  border-radius: 20px;
}
.bacsysQrImageWithLabel img {
  width: 60%;
}
.bacsysTelegramNote {
  display: flex;
  flex-direction: column;
  padding: 20px;
  width: 60%;
  margin-left: auto;
  margin-right: auto;
  border-radius: 20px;
  background-color: var(--container-background-color);
}
.telegramHr {
  margin-top: auto;
  margin-bottom: auto;
  width: 1px;
  height: 80%;
  border: none;
  border-left: 1px solid #ccc;
}
