.usersTable {
  margin-top: 0px !important;
}

.usersTableBtn {
  cursor: pointer;
  padding: 5px;
  background-color: var(--container-background-color);
  backdrop-filter: var(--container-backdrop-filter);
  border-radius: 5px;
  color: var(--light-font-color);
}
