.numericWidgetTitle {
  display: flex;
  width: 85%;
  margin-left: auto;
  margin-right: auto;
  margin-top: 5.5px;
  justify-content: flex-start;
  font-weight: 700;
  font-size: 16px;
}
/* @import url("https://fonts.cdnfonts.com/css/library-3-am"); */
.numericValue {
  width: 85%;
  display: flex;
  align-items: left;
  justify-content: left;
  text-align: left;
  /* font-family: "LIBRARY 3 AM", sans-serif; */
  font-size: 40px;
  margin: auto;
  font-weight: var(--heading-font-weight);
  color: var(--font-color);
}
.numericValue.red {
  color: var(--light-red-color);
}
.numericValue.yellow {
  color: var(--light-yellow-color);
}
.numericWidget {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
  height: 100%;
  overflow: scroll;
}
.switchWidget {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  gap: 10px;
  max-height: fit-content;
  min-height: fit-content;
  overflow: hidden;
}
.switchWidgetTitle {
  font-weight: 700;
  margin-top: 5px;
}
