.objectBrowser {
  display: flex;
  flex-direction: column;
  min-width: 16%;
  height: 49vh;
  justify-content: space-between;
}
.objectBrowserSearch {
  display: flex;
  height: 11%;
}

.objectBrowserHeader {
  display: flex;
  padding: 10px;
  width: 90%;
  height: 4%;
  margin-left: auto;
  margin-right: auto;
  align-items: center;
  font-weight: var(--heading-font-weight);
  gap: 10px;
  font-size: 16px;
  justify-content: space-between;
  text-align: center;
}
.objectBrowserRefresh {
  margin-top: auto;
  margin-bottom: auto;
}
.objectList {
  text-align: left;
  cursor: pointer;
  margin-left: 20px;
}
.objectBrowserContent {
  width: 100%;
  height: 75%;
  overflow: scroll;
}

.objectBrowserContent::-webkit-scrollbar {
  width: 0px;
}
.objectBrowserContent::-webkit-scrollbar:horizontal {
  display: flex !important;
  height: 7px;
}

.objectBrowserContent::-webkit-scrollbar-track {
  background: var(--container-background-color);
  border-radius: var(--container-border-radius);
  border: var(--container-border);
}
.objectBrowserContent::-webkit-scrollbar-thumb {
  border-radius: 20px;
  background: #555;
}
.objectBrowserContent::-webkit-scrollbar-thumb:hover {
  background: var(--font-color);
}
.objectBrowserContent ul {
  text-align: left;
  align-items: start;
  height: fit-content;
}
.objectBrowserContent p {
  margin: 0;
}
.objectBrowserContent li {
  text-align: left;
  margin-bottom: 5px;
  font-size: var(--heading-font-size);
}
.objectBrowserNetworkhead {
  display: flex;
  flex-direction: row;
  gap: 10px;
  padding-bottom: 5px;
  margin-top: auto;
  font-size: var(--heading-font-size);
  line-height: 20px;
  margin-bottom: auto;
  margin: 0px;
}
.objectBrowserhead {
  display: flex;
  flex-direction: row;
  gap: 10px;
  margin-top: auto;
  line-height: 20px;
  margin-bottom: auto;
  margin: 0px;
}
