.rolesSettings {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  gap: 20px;
  height: 100%;
  text-align: center;
}

.rolesSettingsContents {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  border: var(--container-border);
  border-radius: var(--container-border-radius);
  background-color: var(--container-background-color);
  backdrop-filter: var(--container-backdrop-filter);
}

.rolesSettingsHeader {
  width: fit-content;
  background-color: var(--light-heading-color);
  color: var(--light-font-color);
  padding-left: 10px;
  padding-right: 10px;
  padding-top: 5px;
  padding-bottom: 5px;
  margin-top: 20px;
  margin-left: 20px;
  font-weight: 600;
  text-align: center;
  border-radius: 10px;
}

.rolesSettingsContent {
  display: flex;
  margin: auto;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 100%;
  flex-direction: column;
}
.rolesSettingsRolesHeading {
  display: flex;
  width: 100%;
  justify-content: space-between;
}
.rolesSettingsRoles {
  display: flex;
  gap: 40px;
  font-weight: 600;
}
.rolesSettingsRolesHeadingRoleName {
  width: 20%;
  text-align: center;
  font-weight: 600;
}
.rolesSettingsRolesReadWrite {
  display: flex;
  gap: 60px;
  font-weight: 600;
  margin-left: 196px;
}

.rolesSettingsRolesReadWrite p {
  background-color: var(--item-selected-color);
  padding: 5px;
  border-radius: 10px;
}
.rolesSettingsRolesReadWrite.dark p {
  background-color: var(--dark-background-color);
}
.rolesSettingsBtns {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 20px;
  gap: 20px;
}
.roleSettingsSaveBtn {
  display: flex;
  justify-content: center;
  margin-left: auto;
  margin-right: auto;
  margin-top: 20px;
  cursor: pointer;
  align-items: center;
  gap: 5px;
}
.roleSettingsSaveBtnIcon {
  margin: auto;
  font-size: 18px;
}
.addRoleForm {
  display: flex;
  flex-direction: column;
  background-color: white;
  padding: 10px;
  border-radius: 10px;
}
.addRoleHeading {
  font-size: 20px;
  font-weight: 600;
  margin-top: 10px;
}
.addRoleFormInputs {
  margin-top: 20px;
  display: flex;
  flex-direction: column;
}
.addRoleFormInputs label {
  margin-left: 5px;
  font-size: 16px;
}
.addRoleFormInputs input {
  padding: 10px;
  outline: none;
  border-radius: 10px;
  border: none;
  background-color: var(--input-background-color);
}
.addRoleFormBtns {
  display: flex;
  margin-top: 20px;
  gap: 10px;
  justify-content: center;
}
.addRoleFormBtn {
  background-color: var(--light-green-color);
  padding: 5px;
  border-radius: 10px;
  color: white;
  cursor: pointer;
}
.addRoleFormBtn.cancel {
  background-color: var(--light-red-color);
}
.rolesSettingsTable {
  width: 60%;
  height: 50%;
  margin: auto;
}
.rolesSettingsTable thead {
  height: 60px;
}
.rolesSettingsTable tr {
  text-align: left;
}
.roleCheckboxGroup {
  display: flex;
  width: fit-content;
  padding: 0px;
}
.roleCheckboxGroup input {
  display: flex;
  margin: unset;
}
