.commandWidget {
  display: flex;
  flex-direction: column;
  margin: auto;
  height: 100%;
  width: 75%;
  margin: auto;
  justify-content: center;
  /* align-items: center; */
  overflow: scroll;
}
.commandWidgetInput {
  display: flex;
  width: 70px;
  background-color: var(--container-background-color);
  border: var(--container-border);
  outline: none;
  color: var(--font-color);
  border: none;
  padding: 10px;
  border-radius: var(--input-border-radius);
}
.pointWriteInfo {
  display: flex;
}
.pointWriteInfo label {
  width: fit-content;
  margin-top: auto;
  margin-bottom: auto;
}
.pointWriteInfo input {
  margin-top: auto;
  margin-bottom: auto;
  margin-right: 15px;
}
