.pointDetailsTrendsContent {
  display: flex;
  flex-direction: column;
  height: 100%;
  flex-grow: 1;
  width: 100%;
  justify-content: flex-end;
}
.pointDetailsTrendsMainContent {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 90%;
  gap: 20px;
  overflow: scroll;
  margin-bottom: auto;
  margin-top: auto;
}

.trendCheckbox {
  display: flex;
  gap: 20px;
}
.pointDetailsTrendsGroup {
  display: flex;
  width: 100%;
  gap: 10px;
  height: fit-content;
  flex-direction: column;
}

.pointDetailsTrendsLabelNormal {
  width: 45%;
  text-align: left;
  font-weight: 600;
  white-space: nowrap;
  margin-bottom: 12px;
}

.pointDetailsTrendsData {
  text-align: center;
  width: 21%;
  font-size: 16px;
  display: flex;
  outline: none;
  justify-content: center;
  padding: 5px;
  border-radius: 10px;
  border: none;
  background-color: var(--input-background-color);
}
.pointDetailsTrendsEnable {
  text-align: center;
  font-size: 16px;
  display: flex;
  outline: none;
  justify-content: center;
  padding: 5px;
  border-radius: 10px;
  border: none;
  background-color: var(--input-background-color);
}
.pointDetailsTrendsDataTimeGroup {
  text-align: center;
  width: fit-content;
  display: flex;
  background-color: var(--container-background-color);
  backdrop-filter: var(--container-backdrop-filter);
  border-radius: var(--input-border-radius);
  gap: 5px;
  padding: 5px;
  justify-content: center;
  align-items: center;
}
.pointDetailsTrendsDataTime {
  background-color: transparent;
  outline: none;
  border: none;
  padding-top: 5px;
  padding-bottom: 5px;
  width: 20px;
  text-align: center;
  background-color: var(--container-background-color);
  backdrop-filter: var(--container-backdrop-filter);
  border: var(--container-border);
  border-radius: var(--input-border-radius);
  color: var(--font-color);
  margin: auto;
}
.pointDetailsTrendsActivePeriod {
  display: flex;
}
.daysOfWeek {
  display: flex;
  align-items: center;
  font-size: 14px;
  margin-bottom: 10px;
}
.daysOfWeek p {
  color: var(--black-color);
  font-weight: 600;
  font-size: 12px;
}

.saveButtonTrendsProperties {
  background-color: var(--heading-details-color);
  padding-left: 15px;
  padding-right: 15px;
  padding-top: 8px;
  padding-bottom: 8px;
  font-size: 14px;
  cursor: pointer;
  color: var(--light-font-color);
  border-radius: 8px;
  font-weight: 600;
  width: -moz-fit-content;
  width: fit-content;
  margin-right: 220px;
  margin-bottom: 40px;
  margin-top: 20px;
  margin-left: 200px;
}
.checkboxes {
  display: flex;
  flex-wrap: wrap;
  gap: 11px;
}

.checkboxes input[type="checkbox"] {
  margin-right: 5px;
}

.pointDetailsTrendsDays {
  text-align: center;
  width: fit-content;
  height: fit-content;
  font-size: 16px;
  justify-content: center;
  padding: 13px;
  border-radius: 10px;
  border: none;
  background-color: var(--container-background-color);
  backdrop-filter: var(--container-backdrop-filter);
  border: var(--container-border);
  border-radius: var(--input-border-radius);
}
.pointDetailsTrendsTimeRange {
  text-align: center;
  white-space: nowrap;
  font-size: 16px;
  display: flex;
  outline: none;
  border-radius: 10px;
  border: none;
  width: fit-content;
}
.pointDetailsTrendsTimeRange p {
  margin: auto;
  text-align: center;
  font-size: 14px;
  margin-left: 10px;
  margin-right: 10px;
}
.pointDetailsTrendsTimeRange input {
  outline: none;
  border: none;
  padding: 10px;
  background-color: var(--container-background-color);
  backdrop-filter: var(--container-backdrop-filter);
  border: var(--container-border);
  color: var(--font-color);
  border-radius: var(--input-border-radius);
  width: 28%;
}
.pointDetailsTrendsSaveBtn {
  display: flex;
  width: fit-content;
  margin: auto;
  cursor: pointer;
  margin-left: 30%;
  padding-left: 15px;
  padding-top: 10px;
  padding-bottom: 10px;
  padding-right: 15px;
  border-radius: var(--button-border-radius);
  font-weight: 500;
  color: var(--light-font-color);
  background-color: var(--light-heading-color);
}
.customTimeInputGroup {
  text-align: center;
  width: 60%;
  display: flex;
  background-color: var(--container-background-color);
  backdrop-filter: var(--container-backdrop-filter);
  border-radius: var(--input-border-radius);
  gap: 5px;
  padding: 5px;
  justify-content: center;
  align-items: center;
}
.customTimeInput {
  background-color: transparent;
  outline: none;
  border: none;
  padding-top: 5px;
  padding-bottom: 5px;
  text-align: center;
  background-color: var(--container-background-color);
  backdrop-filter: var(--container-backdrop-filter);
  border: var(--container-border);
  border-radius: var(--input-border-radius);
  color: var(--font-color);
  margin: auto;
}
