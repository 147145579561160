.functionBlockHeader {
  padding: 10px;
  font-weight: var(--heading-font-weight);
  text-align: center;
  font-size: var(--custom-header-font-size);
  border-radius: 20px;
}
.functionBlockContent {
  display: flex;
  width: 100%;
  height: 80vh;
  overflow: scroll;
  margin-top: 20px;
  text-align: center;
}
.functionBlocks {
  display: flex;
  flex-wrap: wrap;
  width: 90%;
  margin-left: auto;
  margin-right: auto;
  height: 75vh;
  overflow: scroll;
  text-align: left;
  align-items: center;
  align-content: flex-start;
  justify-content: center;
  gap: 10px;
  background-color: none;
}
.functionBlockOperator {
  font-weight: 600;
  background-color: var(--container-background-color);
  width: 15%;
  border-radius: 10px;
  height: 50px;
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 20px;
  position: relative;
  border: var(--container-border);
}

.inDot,
.outDot {
  font-size: 30px;
  position: absolute;
  top: 50%;
  transform: translateY(-70%);
}
.inDotOne {
  font-size: 30px;
  position: absolute;
  top: 30%;
  transform: translateY(-70%);
}
.inDotTwo {
  font-size: 30px;
  position: absolute;
  top: 60%;
  transform: translateY(-70%);
}
.inDot1 {
  font-size: 60px;
  position: absolute;
  top: 25%;
  transform: translateY(-70%);
}
.inDot2 {
  font-size: 60px;
  position: absolute;
  top: 65%;
  transform: translateY(-70%);
}
.inDot {
  left: -5px;
}
.inDot1 {
  left: -5px;
}
.inDot2 {
  left: -5px;
}
.inDotOne {
  left: -5px;
}
.inDotTwo {
  left: -5px;
}
.outDot {
  right: -5px;
}
.blockContent {
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: center;
  margin: auto;
  gap: 5px;
}
.blockContent span {
  font-weight: var(--base-font-weight);
  text-align: center;
}
