body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
/* body {
  background: radial-gradient(
      circle at top left,
      rgba(255, 100, 50, 0.1),
      transparent
    ),
    radial-gradient(circle at top right, rgba(50, 100, 255, 0.1), transparent),
    radial-gradient(
      circle at bottom right,
      rgba(50, 255, 150, 0.1),
      transparent
    ),
    #0a0f13;
  background-blend-mode: overlay;
} */

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
#webpack-dev-server-client-overlay-div {
  display: none !important;
}
#webpack-dev-server-client-overlay {
  display: none !important;
}
