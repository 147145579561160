.deviceDetailsContentTabs {
  display: flex;
  margin-top: 20px;
  width: 100%;
  margin: auto;
}
.deviceDetailsContentLeft {
  display: flex;
  width: 25%;
  justify-content: center;
  align-items: center;
  margin: auto;
}
.deviceDetailsContentRight {
  display: flex;
  flex-direction: column;
  width: 70%;
  gap: 17px;
  align-items: center;
  height: 70vh;
  margin-left: auto;
  margin-right: auto;
  overflow: scroll;
  margin-bottom: auto;
}
.deviceDetailsHeaderContainerName {
  display: flex;
  flex-direction: row;
  width: 100%;
}

.networkListing {
  padding-left: 8px;
  font-size: var(--heading-size);
  font-weight: 700;
  margin-bottom: 15px;
  font-weight: 500;
}
.deviceDetailsContents {
  display: flex;
  flex-direction: column;
  flex-basis: 100%;
  background-color: var(--background-color);
  border-radius: var(--container-border-radius);
  margin-top: 10px;
}
.deviceDetails {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  gap: var(--gap);
  overflow: scroll;
}
.deviceDetailsTemplate {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  gap: 5px;
  overflow: scroll;
}
.deviceDetailsContent {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  height: 70vh;
  overflow: hidden;
  width: 100%;
  margin-right: auto;
  bottom: 10px;
}

.deviceDetailsHeader {
  width: fit-content;
  color: var(--font-color);
  padding-left: 15px;
  padding-right: 15px;
  padding-top: 9.8px;
  padding-bottom: 9.8px;
  margin-left: 20px;
  text-align: center;
  border-radius: var(--button-border-radius);
}
.devicePropertiesHeaderGroup {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 5%;
  margin-top: 10px;
}
.deviceDetailsHeader.dark {
  background-color: #d1e5fb;
  color: var(--dark-background-color);
}
.deviceDetailsHeaderContainerHeading {
  position: relative;
  right: 130px;
}

.headerDeviceGroup {
  display: flex;
  width: 100%;
  padding-left: 10px;
  padding-top: 10px;
}
.deviceNetworkPropertiesHeader {
  display: flex;
  align-items: center;
  cursor: pointer;
}

.deviceDetailsPoints {
  display: flex;
  width: 10vh;
  flex-direction: column;
  flex-grow: 1;
  box-shadow: 0 0 3px rgba(0, 0, 0, 0.3);
  border-radius: 8px;
  margin-right: 15px;
}

.deviceDetailsHeaderName {
  position: relative;
  right: 12px;
  font-size: 14px;
  width: -moz-fit-content;
  width: fit-content;
  color: var(--font-color);
  font-weight: 400;
  padding-right: 8px;
  padding-top: 10px;
  padding-bottom: 10px;
  margin-left: 25px;
  margin-bottom: 15px;
  text-align: center;
  border-radius: var(--button-border-radius);
}

.deviceDetailsHeaderTabs {
  display: flex;
  flex-direction: row;
  gap: 5px;
  margin-top: 10px;
  bottom: 20px;
  border-bottom: 1px solid black;
  width: 90%;
  margin-left: 50px;
}
.deviceDetailsHeaderTabs span {
  padding: 10px;
  border-top: 1px solid black;
  border-left: 1px solid black;
  border-right: 1px solid black;
  border-top-left-radius: var(--button-border-radius);
  border-top-right-radius: var(--button-border-radius);
  cursor: pointer;
}
.deviceTemplate {
  display: flex;
  flex-direction: column;
  height: 70vh;
  overflow: scroll;
}
