.colorSelectorContainer {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.allColorsContainer {
  display: flex;
  height: 100px;
  overflow: scroll;
  flex-wrap: wrap;
  gap: 8px;
  margin-top: 10px;
  margin: auto;
  width: 80%;
}

.recentColors {
  display: flex;
  overflow: scroll;
  flex-wrap: wrap;
  gap: 8px;
  margin-top: 10px;
  margin: auto;
  width: 80%;
}

.colorSwatchBox,
.recentColorBox {
  width: 30px;
  height: 30px;
  margin-bottom: 5px;
  border-radius: 4px;
  cursor: pointer;
  border: 1px solid #ddd;
}
.colorSwatchBox.selected,
.recentColorBox.selected {
  border: 2px solid #333;
}

.checkmark {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: #fff;
  font-size: 18px;
  font-weight: bold;
}
