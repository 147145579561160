.pointDetailsTags {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  overflow-y: scroll;
}

.pointDetailsTagsContent {
  display: flex;
  flex-direction: column;
}

.tagsInputGroup {
  display: flex;
  flex-direction: column;
  width: 80%;
  gap: 10px;
  margin-top: 20px;
}
.taglist {
  display: flex;
  flex-direction: column;
  gap: 5px;
  width: 30%;
  max-height: 200px;
  overflow-y: auto;
  position: absolute;
  z-index: 1000;
  background-color: var(--dialog-color);
  color: var(--font-color);
  padding: 10px;
  border-radius: 10px;
  margin-top: 80px;
  margin-left: 5px;
  box-shadow: rgba(0, 0, 0, 0.05) 0px 6px 24px 0px,
    rgba(0, 0, 0, 0.08) 0px 0px 0px 1px;
}

.taglist span {
  font-size: 14px;
  width: 95%;
  text-align: left;
  font-weight: 600;
  padding: 10px;
  cursor: pointer;
}

.taglist span:hover {
  background-color: var(--container-background-color);
  border-radius: 10px;
}

.selectedTags {
  display: flex;
  flex-direction: column;
  gap: 5px;
  height: 30.4vh;
  overflow: scroll;
  width: 100%;
  background-color: var(--container-background-color);
  border: var(--container-border);
  border-radius: var(--container-border-radius);
}

.selectedTag {
  display: flex;
  font-size: 14px;
  width: 95%;
  margin-right: auto;
  margin-top: 5px;
  margin-left: auto;
  text-align: left;
  font-weight: 600;
  padding: 10px;
  justify-content: space-between;
}
.selectedTag:hover {
  background-color: var(--container-background-color);
  border-radius: var(--selected-border-radius);
}
.selectedTag span {
  width: 90%;
}
.closeIcon {
  cursor: pointer;
  font-size: 20px;
  font-weight: 600;
  color: var(--light-red-color);
}
