.userProfile {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  background-color: var(--container-background-color);
  border-radius: var(--container-border-radius);
  border: var(--container-border);
  backdrop-filter: var(--container-backdrop-filter);
}
.userProfileHeadingIcon {
  margin: auto;
  font-size: 20px;
}
.userProfileHeading {
  display: flex;
  margin-left: 10px;
  height: fit-content;
  font-weight: 700;
  background-color: var(--background-color-violet);
  color: var(--light-font-color);
  width: fit-content;
  padding: 10px;
  gap: 10px;
  border-radius: 10px;
}
.userProfileContent {
  display: flex;
  flex-direction: column;
  width: 60%;
  height: 70%;
  justify-content: center;
  margin: auto;
  align-items: center;
  justify-content: center;
  border-radius: 10px;
}
.userProfileContentInputs {
  display: flex;
  width: 100%;
  gap: 50px;
  justify-content: center;
  align-items: center;
}
.userSettingsCreateUserFormLeft.left {
  width: 40%;
}
.userSettingsCreateUserFormRight.right {
  width: 40%;
}
.userProfileBtns {
  display: flex;
  flex-direction: row;
  gap: 5px;
}
.userProfileUpdateBtn {
  display: flex;
  margin-top: 30px;
}
.userProfileEmailChangedPopup {
  display: flex;
  height: fit-content;
  width: 20%;
  position: absolute;
  top: 20%;
  left: 40%;
  flex-direction: column;
  background-color: var(--dialog-bg);
  gap: 10px;
  padding: 10px;
  border-radius: var(--container-border-radius);
  border: var(--container-border);
  margin: auto;
}
.userProfilePopupBtns {
  display: flex;
  justify-content: center;
  gap: 10px;
}

.userProfileEmailChangedPopupContentHeading {
  display: flex;
  justify-content: center;
  margin-bottom: 20px;
  margin-top: 10px;
  font-size: 18px;
  font-weight: 600;
}
.userProfileEmailCancelPopupBtn {
  background-color: var(--light-font-color);
  color: var(--font-color);
  border: 1px solid var(--font-color);
  padding-left: 15px;
  padding-right: 15px;
  padding-top: 5px;
  padding-bottom: 5px;
  font-weight: 500;
  font-size: 14px;
  width: fit-content;
  border-radius: var(--button-border-radius);
  cursor: pointer;
}
.userProfileEmailChangedPopupBtn {
  background-color: var(--heading-details-color);
  color: var(--white-color);
  padding-left: 15px;
  padding-right: 15px;
  padding-top: 5px;
  padding-bottom: 5px;
  font-weight: 500;
  font-size: 14px;
  width: fit-content;
  border-radius: var(--button-border-radius);
  cursor: pointer;
}
.userProfileEmailChangedPopupContentForm {
  margin-bottom: 20px;
}

.userProfileOkPopupBtn {
  text-align: center;
  margin-top: 30px;
  cursor: pointer;
}
