.deviceNetwork {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  text-align: center;
  gap: var(--gap);
  height: 100%;
}

.deviceNetworksHeader {
  font-size: var(--heading-size);
  color: var(--header-color);
  margin-bottom: 40px;
  margin-top: 0px;
}

.deviceNetworkBtns {
  display: flex;
  margin-bottom: auto;
  margin-left: 20px;
}
.deviceNetworkBtn {
  width: fit-content;
  padding-left: 10px;
  padding-top: 10px;
  cursor: pointer;
  padding-bottom: 10px;
  border-radius: var(--button-border-radius);
  font-weight: 600;
  padding-right: 10px;
  color: var(--light-font-color);
  background-color: var(--light-heading-color);
}
.deviceNetworkBtn.dark {
  color: var(--black-color);
}
.deviceNetworkTable {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
  overflow: auto;
  margin: auto;
  height: 100%;
  padding-top: 30px;
}

.deviceNetworkTable table {
  width: 100%;
  margin-left: auto;
  margin-right: auto;
  border-collapse: collapse;
}

.deviceNetworkTable thead {
  height: 5%;
  text-align: left;
  font-size: var(--heading-font-size);
  font-weight: var(--heading-font-weight);
  color: var(--font-color);
}

.deviceNetworkTable tbody {
  height: 95%;
  overflow-y: auto;
}

.deviceNetworkTable tbody tr {
  height: auto;
  min-height: 40px;
  cursor: pointer;
}
.deviceNetworkTable tbody tr:hover {
  height: auto;
  min-height: 40px;
  background-color: var(--container-background-color);
  cursor: pointer;
}
.deviceNetworkTable td,
.deviceNetworkTable th {
  padding: 20px;
  text-align: left;
  border-bottom: var(--table-bottom-border);
}
.deviceNetworkTable tbody th {
  font-size: var(--base-font-size);
  font-weight: var(--base-font-weight);
}
.deviceNetworkDevicesBtn {
  background-color: var(--light-heading-color);
  color: var(--font-color);
  width: fit-content;
  cursor: pointer;
  padding-left: 10px;
  padding-right: 10px;
  font-weight: 600;
  font-size: 15px;
  padding-top: 5px;
  padding-bottom: 5px;
  border-radius: 10px;
}

@media screen and (max-width: 768px) {
  .deviceNetwork {
    width: 100%;
    height: 100%;
  }
  .devices {
    width: 100%;
    height: 85%;
  }
}

.deviceNetworkTableData .MuiTableCell-root {
  text-align: left;
  font-weight: bold !important;
}

.bold {
  font-weight: bold !important;
}

.onboardWizard {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;
}
.onboardWizardDialog {
  display: flex;
  flex-direction: column;
  height: 70%;
  width: 80%;
  background-color: var(--dialog-color);
  backdrop-filter: var(--container-backdrop-filter);
  border: var(--container-border);
  border-radius: var(--container-border-radius);
}
.onBoardWizardDialogBtn {
  position: absolute;
  cursor: pointer;
  right: -10px;
  top: -10px;
  display: flex;
  margin: auto;
}
.onBoardWizardDialogContent {
  height: 90%;
  display: flex;
  margin: auto;
}
