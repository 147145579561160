.headerContainer {
  display: flex;
  justify-content: space-between;
  gap: 20px;
  color: var(--font-color);
  padding: 8px;
  height: 5vh;
}

.headerIconsContainer {
  display: flex;
  gap: 20px;
}
.headerBackIcon {
  width: fit-content;
  cursor: pointer;
  margin-top: auto;
  margin-bottom: auto;
  margin-left: 10px;
  font-size: var(--icon-font-size);
  color: var(--white-color);
  padding: 2px;
  border-radius: 4px;
  background-color: var(--primary-color);
}
.headerHeading {
  text-align: left;
  font-size: var(--custom-header-font-size);
  font-weight: var(--custom-header-font-weight);
  margin-top: auto;
  margin-bottom: auto;
}
.headerSearch {
  display: flex;
  background: var(--container-background-color);
  border-radius: 8px;
  border: var(--container-border);
  width: fit-content;
  height: 28px;
  margin-top: auto;
  margin-bottom: auto;
  align-items: center;
}
.headerSearchNone {
  background-color: transparent;
}
.headerSearch input {
  outline: none;
  background-color: transparent;
  border: none;
  margin: auto;
  color: var(--font-color);
}
.headerSearch input::placeholder {
  color: rgb(145, 144, 144);
  font-size: var(--base-font-size);
}
.headerSearchIcon {
  font-size: 15px;
  margin-left: 5px;
  color: rgb(145, 144, 144);
}
.headerIcon {
  margin: auto;
  font-size: 19px;
  cursor: pointer;
}
.customHeaderRightContent {
  display: flex;
  width: fit-content;
  gap: 20px;
  justify-content: flex-end;
}
.customHeaderLeftContent {
  display: flex;
  width: fit-content;
  gap: 20px;
}
.onBoardApex {
  display: flex;
  align-items: center;
  border-radius: 12px;
  background-color: #375dfb;
}
.onBoardApex:hover {
  background-color: #2651fd;
}
