.logicBlockHeader {
  padding: 10px;
  font-weight: var(--heading-font-weight);
  font-size: var(--custom-header-font-size);
  text-align: center;
}
.logicBlockContent {
  display: flex;
  width: 100%;
  height: 80vh;
  overflow: scroll;
  margin-top: 20px;
  text-align: center;
}
.logicBlocks {
  display: flex;
  width: 80%;
  margin-left: auto;
  margin-right: auto;
  text-align: left;
  gap: 10px;
  flex-direction: column;
  background-color: none;
}
.logicBlockOperator {
  font-weight: 600;
  text-align: left;
  cursor: pointer;
}
