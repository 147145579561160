.reports {
  display: flex;
  width: 100%;
  gap: var(--gap);
  height: 100%;
}
.reportsBrowser {
  min-width: 16%;
  gap: 16px;
}
.reportCanvas {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  height: 100%;
  width: 80%;
  gap: var(--gap);
}
.reportCanvasHeadingInput {
  width: 20%;
  display: flex;
  gap: 5px;
}

.reportCanvasBody {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  gap: var(--gap);
  justify-content: space-between;
  overflow: hidden;
}
.reportCanvasBodyBack {
  border-radius: var(--container-border-radius);
  background-color: var(--container-background-color);
  border: var(--container-border);
  backdrop-filter: var(--container-backdrop-filter);
}
.bodyHeader {
  display: flex;
  gap: 5px;
  width: 100%;
  height: 10%;
}
.bodyTitle {
  display: flex;
  position: relative;
  width: 100%;
  height: 10%;
  border-radius: 5px;
}
.bodyFooter {
  display: flex;
  gap: 5px;
  width: 100%;
  height: 10%;
}
.body {
  display: flex;
  width: 100%;
  height: 80%;
  border-radius: 5px;
  font-weight: var(--heading-font-weight);
  align-items: center;
  justify-content: center;
}
.headerPart {
  position: relative;
  display: flex;
  border-radius: 5px;
  flex: 1;
}
.headerPart span,
.footerPart span,
.bodyTitle span {
  width: 100%;
  height: 100%;
  font-size: var(--heading-font-size);
  align-content: center;
  margin: auto;
  text-align: center;
}
.footerPart {
  position: relative;
  display: flex;
  border-radius: 5px;
  flex: 1;
}
.reportCanvasSetting {
  position: absolute;
  right: 2px;
  top: 2px;
  cursor: pointer;
  font-size: 20px;
}
.reportsBrowserContent {
  display: flex;
  flex-direction: column;
  margin: auto;
  width: 90%;
  height: 90%;
  gap: 10px;
  align-items: center;
  overflow: scroll;
}
.templateItem {
  width: 100%;
  display: flex;
}
.templateItem span {
  width: 90%;
  cursor: pointer;
  font-size: var(--heading-font-size);
}
.reportCanvasConfigurationForm {
  display: flex;
  width: 80%;
  margin-left: auto;
  margin-right: auto;
  margin-top: auto;
  margin-bottom: auto;
  height: 70%;
  overflow: scroll;
}
.reportCanvasConfigurationFormLeft {
  width: 50%;
  margin-left: auto;
  margin-right: auto;
  height: 100%;
}
.reportCanvasConfigurationFormRight {
  display: flex;
  flex-direction: column;
  gap: 10px;
  width: 50%;
  height: 100%;
}
.reportCanvasConfigurationFormBtns {
  display: flex;
  width: 100%;
  justify-content: center;
  margin: auto;
}
.pointSelectorOverlay {
  display: flex;
  align-items: center;
}
.assetsSelectionContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: auto;
  background-color: var(--dialog-color);
  border: var(--container-border);
  border-radius: 10px;
  width: 25%;
  height: 60%;
}
.searchContainer {
  display: flex;
  width: 90%;
  margin-top: 10px;
  align-items: center;
}
.assetsSelectionContainerList {
  height: 85%;
  width: 90%;
  margin-right: auto;
  margin-top: 10px;
  overflow: scroll;
}

.assetsSelectionContainerBtns {
  display: flex;
  height: fit-content;
  margin: auto;
  margin-top: 10px;
  margin-bottom: 10px;
  gap: 15px;
  width: 100%;
  justify-content: center;
}
.input-style-card-container {
  display: flex;
  padding: 6px;
  gap: 5px;
  flex-wrap: wrap;
}
.input-style-card {
  display: flex;
  border-radius: 5px;
  padding: 3px;
  background-color: var(--container-background-color);
}
.reportCanvasConfiguration {
  display: flex;
  flex-direction: column;
  margin: auto;
  height: 100%;
  width: 90%;
}
.reportCanvasConfigurationForm,
.reportCanvasConfigurationFormLeft,
.customFormInput {
  overflow: visible;
  margin-top: unset;
  margin-bottom: unset;
}
.overviewTable {
  display: flex;
  flex-direction: column;
  width: 90%;
  gap: 15px;
  margin: auto;
  margin-top: 10px;
  height: 85%;
  overflow: scroll;
}
.overviewTableBtns {
  width: 100%;
  display: flex;
  justify-content: center;
  gap: 20px;
}
.reportTable {
  display: flex;
  overflow: scroll;
  flex-direction: column;
  width: 70vw;
  gap: 10px;
  margin: auto;
  height: 100%;
}
.reportTableContent {
  display: flex;
  flex-direction: column;
  gap: 15px;
  width: 100%;
  height: 85%;
}
.assetsFilter {
  display: flex;
  width: 100%;
  height: 75%;
}
.assetsFilterContainer {
  display: flex;
  flex-wrap: wrap;
  overflow-y: auto;
  overflow-x: hidden;
  background-color: var(--container-background-color);
  border-radius: var(--input-border-radius);
  padding: 10px;
  gap: 5px;
  width: 100%;
  height: 100%;
}

.reportTableHeader {
  display: flex;
  height: 6vh;
}
.assetFilterCard {
  display: flex;
  background-color: var(--container-background-color);
  backdrop-filter: var(--container-backdrop-filter);
  border-radius: var(--input-border-radius);
  padding: 5px;
  gap: 5px;
  justify-content: space-between;
  align-items: center;
}
.reportPreviewWindow {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  overflow: scroll;
  background-color: var(--dialog-color);
}
.reportPreviewWindow {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.reportHeaderBtns {
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin-bottom: 10px;
}

.reportCanvasBody.preview {
  background: #fff;
  color: black;
}

.paginationControls {
  margin-top: 10px;
  display: flex;
  justify-content: space-between;
  width: 50%;
}
.body table {
  width: 80%;
  border-collapse: collapse;
  font-size: 12px;
  /* margin-bottom: 20px; */
}

.body th {
  background-color: #f2f2f2;
  color: #333;
  text-align: left;
  padding: 10px;
  border: 1px solid #ddd;
}

.body td {
  text-align: left;
  padding: 10px;
  border: 1px solid #ddd;
}

.body tbody tr:nth-child(even) {
  background-color: #f9f9f9;
}

.body tbody tr:nth-child(odd) {
  background-color: #ffffff;
}

.body tbody tr:hover {
  background-color: #f1f1f1;
}
.reportCanvasBtns {
  display: flex;
  align-items: center;
  margin-right: 20px;
  gap: 5px;
}
.reportTableBtns {
  margin-bottom: 10px;
}
