.alarmTable {
  display: flex;
  flex-grow: 1;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
  height: fit-content;
  max-height: 80%;
  overflow: hidden;
  margin: auto;
  margin-top: 20px;
  margin-bottom: 20px;
}

.alarmTableContainer {
  max-height: 100%;
  overflow-y: auto;
}
.alarmTableContainer::-webkit-scrollbar {
  width: 5px;
  display: flex;
  height: 5px;
  border-radius: 20px;
}

.alarmTableContainer::-webkit-scrollbar-track {
  background: #f1f1f1;
}
.alarmTableContainer::-webkit-scrollbar-thumb {
  border-radius: 20px;
  background: var(--font-color);
}
.alarmTableContainer::-webkit-scrollbar-thumb:hover {
  background: #555;
}

/* .alarmTableHeader {
  background-color: var(--white-color) !important;
  font-weight: 700 !important;
  font-size: 16px !important;
} */
/* .css-y8ay40-MuiTableCell-root {
  background-color: var(--white-color) !important;
} */
.alarmTableHeader.dark {
  background-color: #d3e3fd;
}
.alarmTablePagination.dark {
  background-color: #d3e3fd;
}
.alarmTableRefreshIcon {
  font-size: 21px;
  margin: auto;
  background-color: var(--item-selected-color);
  padding: 5px;
  cursor: pointer;
  border-radius: 50%;
}
.alarmTableHeaderAscendingDescending {
  font-size: 17px;
  margin-left: 5px;
  cursor: pointer;
}
.alarmTableFilters {
  display: flex;
  width: 100%;
  justify-content: center;

  align-items: center;
  height: 5vh;
  margin: auto;
  gap: 10px;
}
.alarmTableFilter {
  display: flex;
  width: 15%;
  height: fit-content;
  border-radius: 10px;
  background-color: var();
}
.alarmTableSourceFilter select {
  width: 60%;
  border-radius: 10px;
  background-color: var(--item-selected-color);
}
.alarmTableFilter select {
  background-color: var(--item-selected-color);
  text-align: center;
  margin: auto;
  border: 1px solid var(--font-color);
}

.alarmTableFilter input {
  background-color: var(--item-selected-color);
  border: none;
  width: 90%;
  height: fit-content;
  padding: 10px;
  outline: none;
  border-radius: 10px;
  margin: auto;
}
.alarmTableFilters p {
  width: 100px;
  margin: auto;
}
.alarmTableFilterResetBtn {
  display: flex;
  width: fit-content;
  padding-left: 15px;
  padding-top: 9px;
  padding-bottom: 9px;
  padding-right: 15px;
  margin: auto;
  justify-content: center;
  text-align: center;
  color: var(--white-color);
  cursor: pointer;
  border-radius: var(--button-border-radius);
  background-color: var(--heading-details-color);
}
.alarmTableData.acknowledged {
  color: var(--light-green-color);
  font-weight: 500;
}

.alarmTableData.green {
  color: var(--light-green-color);
  font-weight: 500;
}
.alarmTableData.red {
  color: var(--light-red-color);
  font-weight: 500;
}
.alarmTablePriority {
  gap: 5px !important;
}
.alarmTablePriority span {
  margin-left: 5px !important;
}
.alarmDetails {
  display: flex;
  width: 100%;
  height: 100%;
  justify-content: center;
  align-items: center;
}
.alarmDetailsContainer {
  position: relative;
  display: flex;
  height: 70%;
  width: 70%;
  align-items: center;
}
.alarmDetailsCloseIcon {
  font-size: 40px;
  position: absolute;
  cursor: pointer;
  top: -10px;
  right: -10px;
  z-index: 999;
}
