.configurationSettingsMail {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 90%;
  margin: auto;
}
.configurationSettingsMailHeading {
  display: flex;
  margin-top: 20px;
}
.configurationSettingsMailTabs {
  display: flex;
  gap: 5px;
  margin: auto;
  margin-top: 30px;
  border-bottom: 1px solid black;
  white-space: nowrap;
}
.configurationSettingsMailTab {
  padding: 10px;
  border-radius: var(--button-border-radius);
  background-color: var(--item-selected-color);
  cursor: pointer;
  border-top-left-radius: var(--button-border-radius);
  border-top-right-radius: var(--button-border-radius);
  border-bottom-left-radius: 0px;
  border-bottom-right-radius: 0px;
  border-top: 1px solid black;
  border-left: 1px solid black;
  border-right: 1px solid black;
}
.configurationSettingsMailTab.selected {
  background-color: var(--background-color-violet);
  font-weight: 600;
  color: var(--light-font-color);
  border-top-left-radius: var(--button-border-radius);
  border-top-right-radius: var(--button-border-radius);
}
.configurationSettingsMailContent {
  display: flex;
  flex-direction: column;
  gap: 10px;
  width: 50%;
  margin-left: auto;
  margin-right: auto;
  justify-content: center;
  height: 60%;
}
.configurationNotificationsForm {
  display: flex;
  width: 100%;
  height: 100%;
  flex-direction: column;
  margin-top: 20px;
}
.configurationNotificationsHeading {
  display: flex;
  flex-direction: column;
  font-size: 21px;
  font-weight: 600;
  margin-top: 20px;
}
.enableSmtp {
  display: flex;
  width: 100%;
  margin-bottom: 10px;
}
.enableSmtp label {
  width: 40%;
}
.enableSmtpCheck {
  display: flex;
  width: 15px !important;
  margin: 0px !important;
}
.configurationNotificationsFormContent {
  width: 100%;
  height: 100%;
  margin-left: auto;
  margin-right: auto;
}

.configurationNotificationsFormBtns {
  display: flex;
  justify-content: center;
  align-self: center;
  gap: 10px;
  margin-top: 40px;
}
.configurationNotificationsFormBtn {
  display: flex;
  border-radius: 10px;
  padding-left: 15px;
  padding-top: 10px;
  padding-bottom: 10px;
  font-size: 16px;
  color: var(--font-color);
  font-weight: 600;
  margin: auto;
  padding-right: 15px;
  cursor: pointer;
  background-color: var(--heading-details-color);
  color: var(--light-font-color);
}

.configureSmtpAppPasswordGroup {
  display: flex;
  width: 73%;
  margin: auto;
  gap: 40px;
}
.configureSmtpAppPassword {
  width: 65% !important;
  margin: auto !important;
  position: relative;
  left: 13px;
}
.configureSmtpAppPasswordGroup a {
  text-decoration: none;
  position: relative;
  right: 15px;
  border-radius: var(--button-border-radius);
  width: 25%;
  height: fit-content;
  margin: auto;
  padding-left: 10px;
  padding-right: 10px;
  padding-top: 12px;
  padding-bottom: 12px;
  color: var(--font-color);
  background-color: var(--settings-selected-color);
  justify-content: center;
  white-space: nowrap;
}
.configurationSettingsTelegram {
  display: flex;
  margin: auto;
  width: 100%;
  height: 80%;
}
