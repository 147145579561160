.canvas {
  display: flex;
  flex-direction: row;
  height: 100%;
  flex-grow: 1;
}
/* .layout {
  display: grid;
  grid-gap: 10px;
  grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
  grid-auto-rows: minmax(150px, auto);
  grid-auto-flow: dense;
  overflow-y: auto;
  padding: 0px !important;
  margin: 0px !important;
} */
.canvasContent {
  width: 100%;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  align-items: center;
}

.dragHandle {
  position: absolute;
  top: 5px;
  display: flex;
  cursor: move;
  left: 5px;
  font-weight: var(--heading-font-weight);
  justify-content: center;
}

.grid-item {
  width: 100%;
  height: 100%;
}

.button-container {
  text-align: center;
  margin: 16px;
}
.text {
  font-size: 16px;
}
.droppedItem {
  height: 100%;
  width: 100%;
  z-index: 0;
  border-radius: var(--container-border-radius);
  backdrop-filter: var(--container-backdrop-filter);
  background-color: var(--container-background-color);
  overflow: hidden;
}
.droppedItemTemplate {
  height: 100%;
  width: 100%;
  z-index: 0;
  border-radius: var(--container-border-radius);
  background-color: var(--container-background-color);
  overflow: hidden;
}
.droppedItem.widgetSelected {
  border: 2px solid var(--primary-color);
}
/* .droppedItem.tableWidget {
  height: 100%;
  width: 110px;
  border-radius: 20px;
  background-color: white;
} */
.widgetComponent {
  display: flex;
  object-fit: cover;
  height: 100px;
  box-shadow: rgba(0, 0, 0, 0.05) 0px 6px 24px 0px,
    rgba(0, 0, 0, 0.08) 0px 0px 0px 1px;
  border-radius: var(--button-border-radius);
  margin: 10px;
}
.displayRemoveWidget {
  position: absolute;
  cursor: pointer;
  right: 2px;
  padding: 0;
  z-index: 999;
}
.displaySettingsWidget {
  cursor: pointer;
  position: absolute;
  right: 30px;
  top: 5px;
  margin-top: auto;
  margin-bottom: auto;
  z-index: 999;
}

.displayRemoveWidget:hover {
  background-color: transparent;
}
.editNewDashboardBtns {
  display: flex;
  height: 5.3vh;
  padding-top: 8px;
  padding-bottom: 8px;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  z-index: 999;
}
.gridLayout {
  height: 100%;
  width: 100%;
  overflow: scroll;
}
.canvasBtns {
  display: flex;
  width: fit-content;
  align-items: center;
  color: var(--light-font-color);
  height: 100%;
  margin-right: 10px;
  display: flex;
  justify-content: flex-end;
}
.canvasName {
  margin-left: 10px;
  display: flex;
  width: fit-content;
  flex-direction: row;
  height: fit-content;
}
.canvasDisplayName {
  display: flex;
  min-width: fit-content;
  background-color: var(--background-color-violet);
  padding-top: 5px;
  color: var(--light-font-color);
  padding-bottom: 5px;
  padding-left: 15px;
  padding-right: 15px;
  font-size: 15px;
  font-weight: 500;
  border-radius: 8px;
  border: none;
}
.canvasEditViewBtn {
  display: flex;
  flex-direction: row;
  color: black;
  height: fit-content;
  width: fit-content;
  padding-top: 5px;
  padding-bottom: 5px;
  cursor: pointer;
  padding-left: 15px;
  padding-right: 15px;
  font-weight: 600;
  font-size: 15px;
  gap: 10px;
  border-radius: var(--button-border-radius);
}

.canvasExportBtn p {
  margin: auto;
}
.canvasExportBtnIcon {
  margin: auto;
  font-weight: 600;
}
.canvasExportBtn {
  display: flex;
  flex-direction: row;
  background-color: var(--light-heading-color);
  height: fit-content;
  width: fit-content;
  padding-top: 5px;
  padding-bottom: 5px;
  cursor: pointer;
  padding-left: 15px;
  padding-right: 15px;
  font-size: 12px;
  font-weight: 600;
  font-size: 16px;
  gap: 10px;
  border-radius: var(--button-border-radius);
}
.canvasExportBtn.dark {
  color: var(--black-color);
}

.canvasExportImageBtn p {
  margin: auto;
}
.canvasExportImageBtnIcon {
  margin: auto;
  font-weight: 600;
}
.canvasExportImageBtn {
  display: flex;
  flex-direction: row;
  background-color: var(--light-heading-color);
  height: fit-content;
  width: fit-content;
  padding-top: 5px;
  padding-bottom: 5px;
  cursor: pointer;
  padding-left: 15px;
  padding-right: 15px;
  font-size: 12px;
  font-weight: 600;
  font-size: 16px;
  gap: 10px;
  border-radius: var(--button-border-radius);
}
.canvasExportImageBtn.dark {
  color: var(--black-color);
}
.canvasSaveBtnIcon {
  margin: auto;
  font-weight: 600;
}
.canvasSaveBtn {
  display: flex;
  flex-direction: row;
  background-color: var(--light-heading-color);
  height: fit-content;
  width: fit-content;
  padding-top: 5px;
  padding-bottom: 5px;
  cursor: pointer;
  padding-left: 15px;
  padding-right: 15px;
  font-size: 12px;
  font-weight: 600;
  font-size: 16px;
  gap: 10px;
  border-radius: var(--button-border-radius);
}
.canvasSaveBtn p {
  margin: auto;
}
.canvasSaveBtn.dark {
  color: var(--black-color);
}
.canvasClearBtn.dark {
  color: var(--black-color);
}
.canvasEditViewBtn.dark {
  color: var(--black-color);
}
.saveDialog {
  position: absolute;
  display: flex;
  padding: 10px;
  right: 40%;
  left: 40%;
  top: 39%;
  height: fit-content;
  bottom: 39%;
  background-color: var(--dialog-bg);
  z-index: 999;
  flex-direction: column;
}
.saveHeading {
  font-size: 18px;
  text-align: center;
  font-weight: 600;
  margin-bottom: 20px;
}

.saveDialogBtns {
  width: 60%;
  margin: auto;
  margin-top: 30px;
  display: flex;
  gap: 10px;
  justify-content: space-evenly;
}
.preBuiltDisplayCanvas {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  height: 100%;
  margin-top: 10px;
  overflow: scroll;
  border-radius: var(--container-border-radius);
  background-color: var(--background-color);
}
.preBuiltDisplayHeader {
  display: flex;
}
.canvasHeading {
  display: flex;
  background-color: var(--light-heading-color);
  padding-top: 5px;
  color: var(--light-font-color);
  text-align: center;
  width: fit-content;
  padding-bottom: 5px;
  padding-left: 15px;
  padding-right: 15px;
  font-size: 14.3px;
  font-weight: 500;
  border-radius: var(--button-border-radius);
  border: none;
  margin: auto;
  margin-left: 10px;
  height: fit-content;
}
.preBuiltDisplayCanvasContent {
  display: flex;
  width: 100%;
  height: 93%;
}

@media screen and (max-width: 768px) {
  .canvas {
    display: flex;
    width: 10%;
  }
  .canvasName {
    width: fit-content;
    margin-right: 0px;
    height: fit-content;
    z-index: 0;
  }
  .canvasDisplayName {
    width: 10px;
    padding: 5px;
  }
  .canvasName p {
    padding: 5px;
    width: fit-content;
  }
  .canvasEditViewBtn {
    font-size: 12px;
    width: fit-content;
    padding: 5px;
  }
}
.floorLayout {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  height: 100%;
  margin-top: 10px;
  width: 100%;
  margin: auto;
  overflow: scroll;
}
.floorLayoutCanvasImageContainer {
  display: flex;
  flex-direction: column;
  height: 100%;
  object-fit: contain;
  margin-top: 10px;
  width: 98%;
  margin-left: auto;
  margin-right: auto;
  overflow: scroll;
  border-radius: var(--container-border-radius);
}
.floorImageUploadBtn {
  width: fit-content;
  margin: auto;
  text-align: center;
  display: flex;
  font-size: 20px;
  cursor: pointer;
  flex-direction: column;
}
.floorImageUploadImage {
  font-size: 10rem;
  margin-bottom: -30px;
}
.floorImageWidgetImageDeleteBtn {
  position: absolute;
  top: 0;
  right: 5px;
  font-size: 1.6rem;
  color: var(--light-font-color);
  padding: 5px;
  background-color: black;
  border-radius: var(--button-border-radius);
  cursor: pointer;
}
.floorImageWidget {
  width: 98%;
  height: 98%;
}
.floorWidgetsContent {
  flex-direction: column;
  margin-top: 21px;
  width: 100%;
  height: 40%;
  justify-content: center;
  align-items: center;
  gap: 10px;
}
.floorWidgetsContent2 {
  flex-direction: column;
  width: 100%;
  height: 43%;
  margin-top: 10px;
  justify-content: center;
  align-items: center;
  gap: 10px;
}
.floorLayoutWidget {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 80%;
  overflow: scroll;
  margin-top: 20px;
}
.draggableItemsContainer {
  display: flex;
  margin-top: 20px;
  flex-direction: column;
  width: fit-content;
}
.floorWidgetGroup {
  display: flex;
  width: 90%;
  gap: 10px;
  margin-left: auto;
  margin-right: auto;
  justify-content: center;
}
.floorWidgetTitle {
  font-size: 15px;
  width: 80%;
  margin: auto;
  text-align: center;
  font-weight: 600;
  justify-content: center;
  margin-bottom: 20px;
}
.floorWidgets {
  display: flex;
  flex-direction: column;
  min-width: 20%;
  width: 20%;
  gap: 5px;
  margin-left: auto;
  margin-right: auto;
}
.floorWidgetsHeader {
  display: flex;
  width: 100%;
  color: var(--font-color);
  font-size: var(--custom-header-font-size);
  font-weight: var(--custom-header-font-weight);
  justify-content: center;
}
.floorWidgetsSubHeader {
  display: flex;
  width: 100%;
  color: var(--font-color);
  font-size: var(--heading-font-size);
  font-weight: var(--heading-font-weight);
  justify-content: center;
}
.floorWidgetsFormContent {
  width: 100%;
  margin-top: 10px;
  height: 95%;
  overflow: scroll;
}
.floorWidgetsFormContentGroup {
  display: flex;
  flex-direction: column;
  gap: 10px;
  width: 100%;
}
.floorWidgetsFormContentGroup label {
  font-weight: 600;
  text-align: left;
  margin-left: 20px;
}
.floorWidgetsFormContentGroup input {
  width: 30%;
  margin-top: -5px;
  margin-bottom: 5px;
  border-radius: var(--button-border-radius);
}
.updateBtn {
  display: flex;
  width: 50px;
  justify-content: center;
  text-align: center;
  margin: auto;
  margin-bottom: 10px;
  border-radius: var(--button-border-radius);
  background-color: var(--heading-details-color);
  color: var(--light-font-color);
  cursor: pointer;
}

.floorImageWidgetPinDetails {
  display: flex;
  flex-direction: column;
  gap: 10px;
  padding: 15px;
  width: 25%;
  background-color: rgba(0, 0, 0, 0.5);
  color: white;
  border-radius: var(--container-border-radius);
  backdrop-filter: blur(6px);
  z-index: 1100 !important;
}
.floorLayoutPointName {
  font-weight: 600;
  font-size: 16px;
  width: 100%;
  word-wrap: break-word;
}
.floorLayoutPointDesc {
  font-size: 12px;
}
.floorLayoutPointValue {
  font-weight: 600;
  font-size: 22px;
}
.floorImageWidgetPin {
  display: flex;
  background-color: rgba(0, 0, 0, 0.5);
  backdrop-filter: blur(16px);
  border-radius: var(--container-border-radius);
  padding: 5px;
  color: var(--light-font-color);
}
.floorImageWidgetButtonPin {
  display: flex;
  border-radius: var(--container-border-radius);
  padding: 5px;
  color: var(--light-font-color);
}
.floorLayoutPinPointValue {
  font-weight: 600;
  font-size: 16px;
  margin: auto;
  color: var(--white-color);
  margin-left: 5px;
}
.pointPinInactive {
  width: 10px;
  height: 10px;
  color: var(--light-red-color);
  font-size: 3.3rem;
  margin-top: -45px;
  margin-left: -10px;
  margin-bottom: auto;
  border-radius: 50%;
}
.pointPinActive {
  width: 10px;
  height: 10px;
  color: #3ee52b;
  font-size: 3.3rem;
  margin-top: -45px;
  margin-left: -10px;
  margin-bottom: auto;
  border-radius: 50%;
}
.displayTemplateContent {
  gap: 10px;
}
.displayTemplate {
  height: 100%;
  width: 100%;
  overflow: scroll;
}
.displayTemplateItem {
  height: 100%;
  width: 100%;
  z-index: 0;
  overflow: scroll;
}
