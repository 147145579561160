.mapPicker {
  display: flex;
  width: 100%;
  height: 100%;
  justify-content: center;
  align-items: center;
  margin: auto;
}
.mapContainer {
  height: 90% !important;
}
.mapPickerCloseIcon {
  position: absolute;
  z-index: 1000;
  right: 4%;
  top: 3%;
  color: var(--white-color);
  cursor: pointer;
}
.searchBox {
  position: absolute;
  top: 10px;
  left: 50px;
  z-index: 1000;
  width: 300px;
  background-color: var(--dialog-color);
}

.searchSuggestions {
  margin: 0;
  padding: 0;
  list-style: none;
  background: var(--dialog-color);
  border-radius: var(--input-border-radius);
  max-height: 300px;
  width: 300px;
  overflow-y: auto;
  top: 40px;
  position: absolute;
}

.searchSuggestions li {
  padding: 8px;
  cursor: pointer;
}

.searchSuggestions li:hover {
  background: var(--container-background-color);
}
.loading {
  position: absolute;
  font-size: 14px;
  color: var(--font-color);
  margin-top: 5px;
  right: 5px;
}
