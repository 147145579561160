.home,
.orgView {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  gap: 10px;
  height: 100%;
}
.homeHeader,
.orgHeader {
  display: flex;
  width: 100%;
  height: 7%;
  align-items: center;
  overflow: scroll;
}
.homeHeaderHeading,
.orgHeaderHeading {
  display: flex;
  align-items: center;
  gap: 10px;
  font-size: var(--custom-header-font-size);
  font-weight: var(--heading-font-weight);
}
.homeContent,
.orgViewContent,
.siteViewContent {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 10px;
  width: 100%;
  height: 95%;
  overflow: scroll;
}
.homeContentTop {
  display: flex;
  flex-direction: column;
  gap: 10px;
  width: 100%;
  height: 20%;
  overflow: scroll;
}
.homeContentTopHeading,
.homeContentBottomHeading {
  font-size: var(--heading-font-size);
  font-weight: var(--heading-font-weight);
}
.homeContentTopCards,
.homeContentBottomCards {
  display: flex;
  width: 100%;
  height: 100%;
  gap: 10px;
  overflow: scroll;
}
.homeOrgsCard {
  display: flex;
  width: 8%;
  height: 70%;
  flex-direction: column;
  overflow: scroll;
  align-items: flex-start;
  justify-content: space-between;
  padding: 10px;
  border-radius: var(--container-border-radius);
  background-color: var(--container-background-color);
  border: var(--container-border);
  backdrop-filter: var(--container-backdrop-filter);
}
.homeOrgsCardCount {
  font-size: 30px;
  text-align: center;
}
.homeOrgsCardTitle {
  font-size: 12px;
}
/* BOTTOM DASHBOARD STYLE */
.homeContentBottom {
  display: flex;
  flex-direction: column;
  gap: 10px;
  width: 100%;
  height: 80%;
  overflow: scroll;
}
.homeContentBottomCards {
  width: 100%;
  margin: auto;
  overflow: scroll;
  display: flex;
  flex-direction: column;
}
.homeContentBottomCardHeader {
  width: 100px;
  padding: 5px;
  text-align: center;
  border-radius: 20px;
  background-color: var(--container-background-color);
  backdrop-filter: var(--container-backdrop-filter);
  border: var(--container-border);
}
/* .bottomOrgCard {
  display: flex;
  flex-direction: column;
  width: 30vh;
  height: 90%;
  justify-content: space-between;
  background-color: var(--container-background-color);
  backdrop-filter: var(--container-backdrop-filter);
  border-radius: var(--container-border-radius);
  padding: 10px;
  border: var(--container-border);
} */
.bottomSiteCard {
  flex: 0 0 auto;
  display: flex;
  flex-direction: column;
  width: 30vh;
  height: 90%;
  justify-content: space-between;
  background-color: var(--container-background-color);
  backdrop-filter: var(--container-backdrop-filter);
  border-radius: var(--container-border-radius);
  padding: 10px;
  border: var(--container-border);
}
.siteHeading {
  display: flex;
  font-size: var(--custom-header-font-size);
  font-weight: var(--heading-font-weight);
}
.orgHeading {
  text-align: left;
}
.bottomOrgCard {
  flex: 0 0 auto;
  display: flex;
  flex-direction: column;
  width: 30vh;
  height: 90%;
  justify-content: space-between;
  background-color: var(--container-background-color);
  backdrop-filter: var(--container-backdrop-filter);
  border-radius: var(--container-border-radius);
  padding: 10px;
  border: var(--container-border);
  transition: transform 0.3s ease, box-shadow 0.3s ease,
    background-color 0.3s ease;
}
.bottomOrgCard:hover {
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.2);
  background-color: var(--hover-background-color);
}

.orgCardsSpace {
  display: flex;
  flex-wrap: nowrap;
  height: 60%;
  gap: 10px;
  overflow-x: auto;
  overflow-y: hidden;
  width: 93.5vw;
  padding: 10px;
  box-sizing: border-box;
}
.siteCardsSpace {
  display: flex;
  flex-wrap: nowrap;
  height: 40%;
  gap: 10px;
  overflow-x: auto;
  overflow-y: hidden;
  width: 93.5vw;
  padding: 10px;
  box-sizing: border-box;
}
.orgContentTop {
  display: flex;
  flex-direction: column;
  gap: 10px;
  width: 100%;
  height: 50%;
  overflow: scroll;
}
.orgContentBottom {
  display: flex;
  flex-direction: column;
  gap: 10px;
  width: 100%;
  height: 50%;
  overflow: scroll;
}
.orgContentTopCards {
  display: flex;
  width: 100%;
  height: 50%;
  gap: 10px;
  overflow: scroll;
}
.orgContentTopSecondCards {
  display: flex;
  height: 100%;
  width: 100%;
  gap: 10px;
}
.deviceStatisticsCard.orgCardView {
  width: 28%;
  height: 96%;
}

.orgViewTabs {
  width: 30%;
  /* height: 45px; */
  justify-content: right;
  gap: 10px;
  display: flex;
  padding: 0px;
}
.orgViewTabs .customFormLabel {
  margin-bottom: 0px;
}
.orgViewTabs .customFormInput {
  margin-top: auto;
  width: 48%;
  margin-bottom: auto;
  margin-left: unset;
  margin-right: unset;
}
.orgViewTab {
  width: fit-content;
  margin-right: 0px;
  margin-left: unset;
}
.orgHeader {
  justify-content: space-between;
}
.alarmStatisticsCard {
  display: flex;
  width: 68.6%;
  height: 96%;
}
.alarmStatisticsCardLeft {
  display: flex;
  flex-direction: column;
  width: 20%;
  height: 100%;
  margin: auto;
  border-right: var(--container-border);
}
.alarmStatisticsCardLeftTop {
  display: flex;
  width: 90%;
  margin-left: auto;
  margin-top: 10px;
  margin-right: auto;
  height: 49%;
  flex-direction: column;
}
.alarmStatisticsCardLeftBottom {
  display: flex;
  margin-top: 10px;
  height: 49%;
  width: 90%;
  margin-left: auto;
  margin-right: auto;
  flex-direction: column;
}
.alarmCount {
  display: flex;
  height: 100%;
  font-size: var(--custom-header-font-size);
  font-weight: var(--heading-font-weight);
  margin-top: auto;
  margin-bottom: auto;
  align-items: center;
}
.unack {
  color: #f05d1e;
}
.ack {
  color: #35ba62;
}
.high {
  color: #e4c72e;
}
.alarmStatisticsCardCenter {
  display: flex;
  flex-direction: column;
  border-right: var(--container-border);
  width: 65%;
  height: 100%;
}
.alarmStatisticsCardCenterTop {
  display: flex;
  height: 40%;
  width: 90%;
  margin: auto;
  font-size: var(--heading-font-size);
  font-weight: var(--heading-font-weight);
  align-items: center;
}
.alarmStatisticsCardCenterBottom {
  display: flex;
  height: 60%;
  margin: auto;
  width: 90%;
}
.alarmStatisticsCardCenterBottomPriority {
  display: flex;
  width: 35%;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
}
.alarmStatisticsCardRight {
  display: flex;
  width: 15%;
  height: 100%;
  align-items: center;
  justify-content: center;
}
.viewAlarmBtn {
  display: flex;
  height: fit-content;
  width: fit-content;
}
.orgContentBottomCards {
  height: 100%;
  display: flex;
  flex-wrap: wrap;
  width: 93.5vw;
  gap: 10px;
  overflow: scroll;
}

/* Sites Card */

.orgContentBottomCardSite {
  display: flex;
  flex-direction: column;
  gap: 5px;
  flex: 0 0 auto;
  cursor: pointer;
  width: 20%;
  height: 91%;
  padding: 10px;
  background-color: var(--container-background-color);
  backdrop-filter: var(--container-backdrop-filter);
  border: var(--container-border);
  border-radius: var(--container-border-radius);
  transition: transform 0.3s ease, box-shadow 0.3s ease,
    background-color 0.3s ease;
}

.orgContentBottomCardSite:hover {
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.2);
  background-color: var(--hover-background-color);
}
.bottomCardSiteOverview {
  display: flex;
  height: 23%;
  gap: 10px;
  justify-content: space-evenly;
  width: 90%;
  margin: auto;
  flex-direction: column;
}
.bottomCardSiteOverviewHeading {
  display: flex;
  height: 40%;
  font-size: var(--custom-header-font-size);
  font-weight: var(--heading-font-weight);
  width: 100%;
}
.bottomCardSiteOverviewContent,
.bottomCardSiteAlertsContent {
  display: flex;
  gap: 10px;
  font-size: var(--base-font-size);
  font-weight: 400;
}
.bottomCardSiteOverviewContentPart,
.bottomCardSiteAlertsContentPart {
  display: flex;
  gap: 10px;
}
.bottomCardSiteOverviewContentPartIcon {
  display: flex;
  align-items: center;
  gap: 5px;
}
.cardCount {
  font-size: var(--heading-font-size);
  font-weight: var(--heading-font-weight);
}
.bottomCardSiteAlerts {
  display: flex;
  height: 38.5%;
  justify-content: center;
  width: 90%;
  margin: auto;
  flex-direction: column;
}
.bottomCardSiteAlertsHeading,
.bottomCardSiteGatewaysPartHeading {
  display: flex;
  height: 40%;
  font-weight: var(--heading-font-weight);
  width: 100%;
}
.bottomCardSiteGateways {
  display: flex;
  gap: 20px;
  height: 38.5%;
  justify-content: space-between;
  width: 90%;
  margin: auto;
}
.bottomCardSiteGatewaysPartContent {
  display: flex;
  flex-direction: column;
  gap: 10px;
}
.bottomCardSiteGatewaysPartContentRow {
  justify-content: space-between;
  display: flex;
  gap: 20px;
}

/* MAP VIEW STYLES */
.mapViewContent,
.instanceViewContent {
  display: flex;
  height: 100%;
  gap: 10px;
  flex-grow: 1;
}
.mapViewLeft {
  width: 65%;
  height: 100%;
}
.instanceViewContentLeft {
  display: flex;
  flex-direction: column;
  gap: 10px;
  width: 65%;
  height: 100%;
}
.mapViewRight,
.instanceViewContentRight {
  display: flex;
  flex-direction: column;
  gap: 10px;
  width: 45%;
  height: 100%;
}
.mapViewLeftHeading,
.mapViewRightHeading {
  display: flex;
  height: 5%;
  font-size: var(--heading-font-size);
  font-weight: var(--heading-font-weight);
}
.instanceViewContentRightHeading {
  display: flex;
  align-items: center;
  height: 10%;
  font-size: var(--heading-font-size);
  font-weight: var(--heading-font-weight);
}
.instanceViewContentLeftHeading {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 10%;
  font-size: var(--heading-font-size);
  font-weight: var(--heading-font-weight);
}
.instanceViewContentLeftHeading .customFormLabel {
  display: none;
}
.instanceViewContentLeftHeading span {
  width: 80%;
}
.instanceViewContentLeftHeading .customFormInput {
  margin-top: auto;
  margin-bottom: auto;
  width: 20%;
}
.mapViewLeftContent {
  height: 95%;
}
.mapViewRightPart {
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 10px;
}
.mapViewRightPart.top {
  height: 58%;
}
.mapViewRightPart.bottom {
  height: 41%;
}
.mapViewRightContent {
  display: flex;
  height: 95%;
  width: 100%;
  background-color: var(--container-background-color);
  backdrop-filter: var(--container-backdrop-filter);
  border-radius: var(--container-border-radius);
  border: var(--container-border);
}
.mapViewTreeStructure {
  display: flex;
  width: 90%;
  height: 90%;
  margin: auto;
}
.treeItemHeading {
  display: flex;
  align-items: center;
  gap: 5px;
  font-size: var(--heading-font-size);
  font-weight: var(--heading-font-weight);
}
.treeSiiteList {
  width: 90%;
  gap: 0px;
}
.treeEdgesList {
  display: flex;
  width: 70%;
  margin: auto;
  flex-direction: column;
}
.edgeItem {
  text-align: left;
  padding: 5px;
  cursor: pointer;
}
.mapViewRightContentStatistics {
  height: 95%;
  width: 100%;
}

/* INSTANCE VIEW STYLES */
.instanceViewContentLeftContentTop {
  display: flex;
  width: 100%;
  height: 50%;
  gap: 10px;
}
.instanceViewContentLeftContentTopLeft {
  display: flex;
  flex-direction: column;
  background-color: var(--container-background-color);
  backdrop-filter: var(--container-backdrop-filter);
  border: var(--container-border);
  border-radius: var(--container-border-radius);
  width: 50%;
}
.instanceViewContentLeftContentLeftHeading {
  display: flex;
  width: 90%;
  margin-left: auto;
  margin-right: auto;
  height: 10%;
  align-items: center;
  font-size: var(--heading-font-size);
}
.cpuChartContainer {
  display: flex;
  width: 100%;
  margin-left: auto;
  margin-right: auto;
  height: 90%;
  align-items: center;
  font-size: var(--heading-font-size);
}
.instanceViewContentLeftContentTopRight {
  display: flex;
  flex-direction: column;
  width: 50%;
  height: 100%;
  gap: 10px;
}
.instanceViewContentLeftContentTopRightPart {
  width: 50%;
  height: 98%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 10px;
  background-color: var(--container-background-color);
  backdrop-filter: var(--container-backdrop-filter);
  border: var(--container-border);
  border-radius: var(--container-border-radius);
}
.instanceViewContentLeftContentTopRightPartHeading {
  display: flex;
  width: 95%;
  margin-left: auto;
  margin-right: auto;
  align-items: center;
  height: 15%;
  font-size: var(--heading-font-size);
}
.instanceViewUsageGraph {
  display: flex;
  height: 100%;
  width: 100%;
  margin: auto;
}
.instanceViewContentLeftContentBottom {
  display: flex;
  flex-direction: column;
  gap: 10px;
  width: 100%;
  height: 50%;
}
.instanceViewContentLeftContentTable {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: auto;
  gap: 10px;
  height: 100%;
  width: 100%;
}
.instanceViewContentLeftContentTopRightContent {
  height: 85%;
  width: 90%;
  margin: auto;
}
.instanceViewContentRightContent {
  display: flex;
  height: 95%;
  gap: 10px;
  flex-direction: column;
}
.platFormStats {
  display: flex;
  gap: 5px;
  width: 100%;
  height: 48%;
}
.platFormStatsCard {
  width: 33.3%;
  height: 100%;
  display: flex;
  flex-direction: column;
  background-color: var(--container-background-color);
  backdrop-filter: var(--container-backdrop-filter);
  border: var(--container-border);
  border-radius: var(--container-border-radius);
}
.platFormStatsCardHeading {
  display: flex;
  width: 90%;
  height: 20%;
  margin-left: auto;
  margin-right: auto;
  font-size: var(--base-font-size);
  align-items: center;
}
.platFormStatsCardContent {
  display: flex;
  width: 90%;
  height: 80%;
  margin: auto;
  text-align: center;
  justify-content: center;
  font-weight: var(--heading-font-weight);
  font-size: 40px;
  align-items: center;
}
.platFormHealth {
  display: flex;
  flex-direction: column;
  gap: 10px;
  width: 100%;
  height: 49.5%;
}
.platFormHealthHeading,
.platFormServiceLogsHeading {
  display: flex;
  align-items: center;
  font-weight: var(--heading-font-weight);
  height: 10%;
}
.platFormHealthContent {
  height: 90%;
  padding: 15px;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  gap: 10px;
  margin: auto;
  background-color: var(--container-background-color);
  backdrop-filter: var(--container-backdrop-filter);
  border-radius: var(--container-border-radius);
  border: var(--container-border);
}
.platFormHealthContentItem {
  display: flex;
  gap: 5px;
  align-items: center;
  width: 40%;
  height: 10%;
}
.platFormServiceLogs {
  display: flex;
  gap: 10px;
  flex-direction: column;
  width: 100%;
  height: 33.3%;
}
.platFormServiceLogsContent {
  height: 90%;
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  margin: auto;
  background-color: var(--black-color);
  color: var(--light-green-color);
  backdrop-filter: var(--container-backdrop-filter);
  border-radius: var(--container-border-radius);
  border: var(--container-border);
}
/* SITE VIEW STYLES */
.siteView {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  gap: 10px;
}
.siteViewTop,
.siteViewBottom {
  display: flex;
  flex-direction: column;
  gap: 10px;
  width: 100%;
  height: 48%;
}
.siteContentTopCards {
  display: flex;
  width: 100%;
  height: 30%;
  gap: 10px;
}
.siteContentTopSecondCards {
  display: flex;
  gap: 10px;
  height: 60%;
  width: 100%;
}
.siteAlarmCard {
  display: flex;
  height: 100%;
  width: 68.9%;
}
.siteCardSecond {
  display: flex;
  flex-direction: column;
  gap: 10px;
  width: 30%;
  height: 100%;
}
.siteDeviceCard {
  display: flex;
  height: 50%;
  width: 99%;
}
.siteViewBottomContent {
  display: flex;
  gap: 5px;
  gap: 10px;
  height: 100%;
  width: 100%;
}
.siteApexCard {
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 5px;
  width: 15%;
  align-items: flex-start;
  overflow: scroll;
  background-color: var(--container-background-color);
  backdrop-filter: var(--container-backdrop-filter);
  border-radius: var(--container-border-radius);
  border: var(--container-border);
  height: 100%;
}
.siteApexCards {
  display: flex;
  flex-direction: column;
  height: 90%;
  margin: auto;
  gap: 10px;
  width: 100%;
  align-items: center;
  overflow: scroll;
}
.siteApexCards span {
  padding: 10px;
  width: 80%;
  border-radius: var(--input-border-radius);
  align-items: center;
  cursor: pointer;
  border: var(--container-border);
}
.siteApexCards span:hover {
  background-color: var(--container-background-color);
  backdrop-filter: var(--container-backdrop-filter);
}
.siteApexCardContent {
  display: flex;
  height: 99%;
  width: 55%;
}
.noApex {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 12px;
  text-align: center;
  white-space: nowrap;
  height: 100%;
  margin-top: auto;
  color: var(--disabled-color);
}
