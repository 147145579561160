.sidenav {
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 9vh;
}
.sidenavMenu {
  display: flex;
  justify-content: center;
}
.menuItems {
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: auto;
  gap: 10px;
  margin-top: 20px;
  align-items: center;
}
.sidenavMenuItem {
  display: flex;
  margin: auto;
  width: 100%;
  align-items: center;
  justify-content: center;
}
.sidenavMenuIcon {
  display: flex;
  margin: auto;
  padding: 11px;
}
.sidenavMenuText {
  color: var(--font-color);
  text-align: center;
  font-size: var(--base-font-size);
}
.navLink {
  display: flex;
  gap: 2px;
  flex-direction: column;
  align-items: center;
  text-decoration: none;
  color: var(--font-color);
}
