.header {
  display: flex;
  width: 100%;
  height: 100%;
  /* margin: auto; */
  justify-content: space-between;
  border-radius: var(--container-border-radius);
}

.header-content {
  display: flex;
  width: 25%;
  align-items: center;
  justify-content: flex-end;
  gap: 8px;
  margin-right: 20px;
}
.globalSearchInput {
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: var(--container-background-color);
  border: var(--container-border);
  padding: 5px;
  width: 60%;
  flex-direction: column;
  border-radius: var(--container-border-radius);
  backdrop-filter: var(--container-backdrop-filter);
}

.globalSearchInputContainer {
  display: flex;
  width: 95%;
  margin: auto;
  justify-content: space-between;
  align-items: center;
}
.globalSearchInputContainer input {
  width: 90%;
  outline: none;
  border: none;
  color: var(--font-color);
  background-color: transparent;
}
.globalSearchResults {
  display: flex;
  position: absolute;
  flex-direction: column;
  width: 100%;
  height: 150px;
  gap: 10px;
  padding: 10px;
  overflow: scroll;
  margin-top: 15px;
  z-index: 2000;
  will-change: transform;
  border-radius: 10px;
  backdrop-filter: var(--container-backdrop-filter);
  border: var(--container-border);
  background-color: var(--container-background-color);
  margin-left: 8px;
  box-shadow: rgba(0, 0, 0, 0.05) 0px 6px 24px 0px,
    rgba(0, 0, 0, 0.08) 0px 0px 0px 1px;
}
.globalSearchResult {
  padding: 10px;
}
.globalSearchResult:hover {
  background-color: var(--container-background-color);
  border-radius: var(--input-border-radius);
}
.globalResultText {
  display: flex;
  flex-direction: column;
  overflow: scroll;
  cursor: pointer;
}
.resultName {
  font-size: var(--heading-font-size);
  font-weight: 800;
}
.resultBelongsTo {
  font-size: 12px;
  font-weight: 400;
  color: var(--font-color);
}
.header-logo {
  display: flex;
  width: 10%;
  align-items: center;
  justify-content: center;
}
.header-logo img {
  width: 50%;
  height: 50%;
  margin-top: auto;
  margin-bottom: auto;
}
.appHeaderContainer {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: 45%;
}
.header-welcome {
  display: flex;
  width: 90%;
  font-size: var(--custom-header-font-size);
  font-weight: var(--heading-font-weight);
  align-items: center;
}
